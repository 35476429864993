import React, { useEffect,useContext, useState,useRef} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import LoaderCircle from 'components/LoaderCircle';
import { Header } from './components';
import { useLocation } from 'react-router-dom';
import NFTs from './components/NFTs';
import { getUser } from 'services/User/getUser';
import { useFetch } from 'hooks/useFetch';
import ErrorMessage from 'components/ErrorMessage';
import ButtonStyled from 'components/ButtonStyled';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import OutsideClickHandler from 'react-outside-click-handler';
import CloseIcon from '@mui/icons-material/Close';
import { Context } from 'hooks/WalletContext';

const Profile = () => {
    const {t} = useTranslation("translate");
    const {data} = React.useContext(Context);
    const {setOpenWallet } = useContext(DrawerMobileContext);
    const location = useLocation();
    const query = new URLSearchParams(location.search)
    let address = query.get("address");
    const [loadProfile,setLoadProfile] = useState(true);
    const [user,setUser] = useState(null);
    const [dataTemp,setDataTemp] = useState(
        {
            username:'',
            email:''
        }
    );
    const [formEditProfile,setFormEditProfile] = useState({
        avatar:'',
        fileAvatar: [],
        banner:'',
        fileBanner: [],
        username:'',
        email:'',
        country:'',
        social_media:{
            twitter:'',
            instagram:'',
        },
        validateAttr:{username:null,email:null,avatar:null,banner:null,instagram:null,twitter:null}
    });
    const [finishAddCards,setFinishAddCards] = useState(false);
    const [firstRequest,setFirstRequest] = useState(true);
    const changeFilter = useRef(false)

    let listNFT = 100;
    let listCards = 8;
    const limitMyNFTRef = useRef(listNFT);
    const countMyNFT = useRef(listNFT);
    const pageMyNFTRef = useRef(0);
    const [sliceMyNFT,setSliceMyNFT] = useState(listCards);
    const [newsMyNFT,setNewsMyNFT] = useState([]);
    const [ownerNFTUrl, setOwnerNFTUrl] = useState(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`);

    const [loadMyData,setLoadMyData] = useState(false);
    const [openModalForm,setOpenModalForm] = useState(false);
    const [openPopText,setOpenPopText] = useState(false);

    let timeOut = null;
    const getMyDataProfile = async ()=>{
        clearTimeout(timeOut);
        setLoadMyData(true);
        setOpenModalForm(false);
        let userData = null;
        try{
            userData = await getUser(address).finally(()=>{setLoadMyData(false);}).catch(err=>{setLoadMyData(false);console.log("error profile",err);})
            if(userData && userData.username && userData.registered == true){
                setFormEditProfile({...formEditProfile,
                    username:userData.username, email : userData.email,
                    avatar:userData.profile_pic_url,
                    banner:userData.banner_original,
                    country : userData.country,  description : userData.about, social_media : {
                    twitter : userData.twitter,
                    instagram : userData.facebook,
                }});
                setDataTemp({...dataTemp,
                    username:userData.username, email : userData.email,
                    avatar:userData.profile_pic_url,
                    banner:userData.banner_original,
                    country : userData.country,  description : userData.about, social_media : {
                    twitter : userData.twitter,
                    instagram : userData.facebook,
                }});
                setLoadMyData(false)
                setOpenModalForm(true);
            }else{
                clearTimeout(timeOut);
                if(userData.registered == false){
                    setOpenPopText(true);
                }
                setLoadMyData(false)
            }
        }catch(e){
            console.log("error::",e);
        }
    }

    const getDataProfile = async ()=>{
        setFormEditProfile({...formEditProfile,username:'',avatar:'',fileAvatar:[],email:'',banner:'',fileBanner:[]});
        setDataTemp({...dataTemp,username:'', email :''});
        setLoadProfile(true)
        let isOwner = false
        let wallet = localStorage.getItem('wallet')
        if (wallet) {
            isOwner =  (address.toUpperCase() == wallet.toUpperCase()) ? true : false
        }
        let userData = await getUser(address,isOwner).finally(()=>{
            setLoadProfile(false);
        }).catch(err=>{
            console.log("error profile",err);
            setLoadProfile(false)
        });
        if(userData) {
            setUser(userData)
            if(userData != null  && userData.username){
                setFormEditProfile({...formEditProfile,
                    username:userData.username, email : userData.email,
                    avatar:userData.profile_pic_url,
                    banner:userData.banner_url,
                    country : userData.country,  description : userData.about, social_media : {
                    twitter : userData.twitter,
                    instagram : userData.facebook,
                }});
                setDataTemp({...dataTemp,
                    username:userData.username, email : userData.email,
                    avatar:userData.profile_pic_url,
                    banner:userData.banner_url,
                    country : userData.country,  description : userData.about, social_media : {
                    twitter : userData.twitter,
                    instagram : userData.facebook,
                }});
            }
        }
    }


    const getMyNfts = () =>{
        if(!loaderNFTsOwner){
            if(sliceMyNFT < (countMyNFT.current - 20)){
                return 0;
            }
            countMyNFT.current = countMyNFT.current + limitMyNFTRef.current
            pageMyNFTRef.current++;
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`);
        }
    }


    let {data: NFTsOwner, loading: loaderNFTsOwner, error: errorNFTsOwner}  = useFetch(ownerNFTUrl);

    const handleConcatMyNfts = async() =>{
        if(NFTsOwner && NFTsOwner.length > 0 && !loaderNFTsOwner){
            let newArray = [];
            newArray = NFTsOwner;
            setNewsMyNFT([...newsMyNFT,...newArray.filter((nft)=> nft.transaction && nft.transaction.toUpperCase() != "PRE_MINT" && nft.transaction.toUpperCase() != "0X0")]);
            setFinishAddCards(true);
        }
    }

    useEffect(()=>{
        setFinishAddCards(false);
        handleConcatMyNfts();
    },[NFTsOwner,loaderNFTsOwner])

    useEffect(()=>{ //infinity scroll
        getMyNfts();
    },[sliceMyNFT])

    useEffect(() => {
        limitMyNFTRef.current = listNFT;
        countMyNFT.current =listNFT;
        pageMyNFTRef.current = 0;
        setSliceMyNFT(listCards)
        setNewsMyNFT([]);
        getDataProfile();
        setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`);
    },[address]);

    useEffect(()=>{ // !important show cards when finish request
        setFirstRequest(false);
        if(!firstRequest){
            changeFilter.current = false;
        }
    },[changeFilter,NFTsOwner,loaderNFTsOwner]);

    if(address === null || loadProfile){
        return <Box sx={{width:'100vw',height:'80vh',display:'flex',justifyContent:'center',alignItems:'center'}}><LoaderCircle text={t("profile.message_load.loading_profile")} /></Box>
    }

    return (
        <Box sx={{position:'relative'}}>
            {
            !loadProfile && 
            <Container maxWidth="xl">
                <Header 
                    formEditProfile={formEditProfile}
                    setFormEditProfile={setFormEditProfile}
                    address={address}
                    nftOption={true}
                    totalNFTs={newsMyNFT.length}
                    getMyDataProfile={getMyDataProfile}
                    loadMyData={loadMyData}
                    user={user}
                    data={data}
                    dataTemp={dataTemp}
                    setDataTemp={setDataTemp}
                    openModalForm={openModalForm}
                    setOpenModalForm={setOpenModalForm}
                />
                <Box
                    sx={{width:'100%',mt:'46px'}}
                >
                    {
                        !errorNFTsOwner && !firstRequest && !changeFilter.current &&  
                        <NFTs
                            content={newsMyNFT}
                            limit={sliceMyNFT}
                            setSliceNFT={setSliceMyNFT}
                            loadingNFTs={loaderNFTsOwner}
                            listCards={listCards}
                            firstRequest={firstRequest}
                            finishAddCards={finishAddCards}
                        />
                    }
                    {
                        !errorNFTsOwner && loaderNFTsOwner && !finishAddCards && 
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <LoaderCircle text={t("profile.message_load.loading_nfts")} />
                        </Box>
                    }
                    {
                        !loaderNFTsOwner && errorNFTsOwner && 
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ErrorMessage error={errorNFTsOwner} />
                        </Box>
                    }
                </Box>
            </Container>
            }
            {
                openPopText &&
                <Box
                    sx={{
                        position:'absolute',
                        top:'0px',
                        right:'0px',
                    }}
                >
                    <Box
                        sx={{
                            width: {xs:'100%',sm:'328px',md:'328px'},
                            height:  {xs:'170px',sm:'170px',md:'170px'},
                            backgroundColor: '#f2f2f2 !important',
                            position: 'fixed',
                            bottom: {xs:'0px',sm:'15px'},
                            right: {xs:'0px',sm:'15px'},
                            zIndex: '99999 !important',
                            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.5)',
                            "&:hover": {
                                opacity: '1',
                            
                            }
                        }}
                    >
                        <OutsideClickHandler onOutsideClick={()=>setOpenPopText(false)}>      
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Box  
                                    sx={{
                                        pt:'10px',
                                        pr:'10px'
                                    }}
                                >
                                    <CloseIcon onClick={()=>setOpenPopText(false)}
                                        sx={{
                                            fontSize: '30px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    p: '0px 10px',
                                    fontSize: {xs:'22px',sm:'22px',md:'20px'},
                                    textAlign: 'center'
                                }}
                            >
                                {t("profile.register_pop.register_your_email_text")} <br/> {t("profile.register_pop.complete_your_profile_text")}
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    p: '0px 10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <ButtonStyled
                                    text={t("profile.register_pop.here_btn")}
                                    onClick={async()=>{setOpenPopText(false); setOpenWallet(true)}}
                                />
                            </Box>
                        </OutsideClickHandler>
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default Profile;
