import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmailInputType1  = ({isEditProfile,form,setForm,placeholder,name,label,emailTemp,width,helperText}) =>{
    const {t} = useTranslation("translate");
    const [validInput,setValidInput] = React.useState(true);
    const [existEmail,setExistEmail] = React.useState(false);
    const [msgInput,setMsgInput] = React.useState('');

    let typingTimer;

    const defineMsg = () =>{
        if(!validInput){
            setMsgInput(t("profile.modal_edit.enter_valid_email"))
        }else
        if(existEmail){
            setMsgInput("El correo ya existe")
        }else
            setMsgInput(t("profile.modal_edit.enter_email"))
    }

    const validateEmail = (email) =>{
        return new RegExp(/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(email)
    }

    const validEmailInBD = (email) => {
        console.log(email)
        return true

    }

    const handleOnChange = (e)=>{
        clearTimeout(typingTimer)
        const {name,value} = e.target;
 
        if(value != ''){
            setForm({...form, [name]: (value+'').replace(/ /g, ""),validateAttr:{...form.validateAttr,email:false}});  
        }else{
            setForm({...form, [name]: ''});
            setValidInput(true)
        }       
    }


    const handleKeyUp = () =>{
        clearTimeout(typingTimer)
        if(form.email != ''){
            if(emailTemp != form.email){
                if(validateEmail(form.email) ){
                    setValidInput(true)
                    setForm({...form,validateAttr:{...form.validateAttr,email:true}});  
                    setExistEmail(false)
                    typingTimer  = setTimeout(()=>{
                        if(validEmailInBD((form.email+'').replace(/ /g, ""))){
                            setExistEmail(false)
                        }else{
                            setExistEmail(true)
                        }
                        clearTimeout(typingTimer)
                    },700)
                }else{
                    setValidInput(false)
                    setExistEmail(false)
                    setForm({...form,validateAttr:{...form.validateAttr,email:false}});  
                }
            }else{
              
                setValidInput(true)
                setExistEmail(true)
              
            }
            defineMsg()
        }

       
    }

    const handleKeyDown = () =>{
        clearTimeout(typingTimer)
    }
    


    return (
        <React.Fragment>
            <TextField
                sx={{
                    width:width,
                    marginTop:'20px',
                    color:'#fff',
                    "& ,input":{
                        color:'#fff'
                    },
                    "& label.Mui-focused":{
                        color:'#fff'
                    },
                    "& label":{
                        color:'#fff'
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: 'green',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: '#fff',
                        },
                        '&:hover fieldset': {
                        borderColor: '#43B02A',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: '#43B02A',
                        }
                    },
                    "@media (max-width:768px)": {
                        width:'100%'
                    }
                }}
                type="email"
                error = {!validInput}
                id="standard-error-helper-text"
                label={label}
                helperText={msgInput != '' ? msgInput : helperText}
                variant="standard"
                autoComplete='off'
                required
                name={name}
                value={form.email}
                disabled = {isEditProfile}
                placeholder={placeholder}
                onChange={(e)=>{handleOnChange(e)}}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
            />
        </React.Fragment>
    )
};

EmailInputType1.defaultProps = {
    placeholder: 'email',
    name: 'email',
    label:'Email',
    helperText:'Enter value',
    width: '45%'
}


EmailInputType1 .propTypes = {
    width: PropTypes.any,
    setMsg: PropTypes.func,
    setError: PropTypes.func,
    isEditProfile: PropTypes.bool,
    form: PropTypes.object,
    setForm: PropTypes.func,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    emailTemp: PropTypes.string
};

export default EmailInputType1 ;
