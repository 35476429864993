import Web3 from 'web3'
import Contract from './PerseaSimpleCollection.json'

export const totalLeft = async(project) => {
    let web3 = new Web3(project.blockchain.rpc)
    let contract = new web3.eth.Contract(Contract.abi,web3.utils.toChecksumAddress(project.project_key))
    let left = await contract.methods.totalLeft().call()
    console.log('left',left)
    return parseInt(left)
}


export const getCurrentPrice = async(project) => {
    let web3 = new Web3(project.blockchain.rpc)
    let contract = new web3.eth.Contract(Contract.abi,web3.utils.toChecksumAddress(project.project_key))
    let price = await contract.methods.currentPrice().call()
    console.log('price', price)
    return price
}

export const getCurrentPriceFormat = async(project) => {
    try {
        let web3 = new Web3(project.blockchain.rpc)
        let contract = new web3.eth.Contract(Contract.abi,web3.utils.toChecksumAddress(project.project_key))
        let price = await contract.methods.currentPrice().call()
        price =  web3.utils.fromWei(""+price,'ether')
        return price
    } catch (error) {
        return 0
    }
}
