import React, {useContext} from 'react';
import { BrowserRouter, Route , Routes as RoutesMain, Navigate } from "react-router-dom";
// import Marketplace from 'views/Marketplace'; 
import Main from 'layouts/Main';
import ContainerNetwork from 'layouts/ContainerNetwork';
import { Context } from 'hooks/WalletContext';

import Create from 'views/Create';
import CollectionBuy from 'views/CollectionBuy';
import Profile from 'views/Profile';

import PreMintMassive  from 'views/PreMintMassive'
import CreateCollection   from 'views/CreateCollection'
import SelectCollection  from 'views/SelectCollection'
import Collection  from 'views/Collection'
import EditCollection  from 'views/EditCollection'
import Collections  from 'views/Collections'
import NFT  from 'views/NFT'

const Routes = () => {
    const { data } = useContext(Context);
   
    return (
        <React.Fragment>
            <BrowserRouter>
            <Main>
                <ContainerNetwork>
                    <RoutesMain>
                        <Route path='/' element={<Navigate to="/collections" replace />} />
                        <Route path='/home' element={<Navigate to="/collections" replace />} />
                        <Route path='/collections' element={<Collections />} />
                        <Route path='/collection' element={<Collection />} />
                        <Route path='/collection-buy' element={< CollectionBuy />} />
                        <Route path='/profile' element={<Profile/>} />
                        <Route path='/nft' element={<NFT/>} />

                        {data && data.user && data.user.role > 1 && data && data.userAccount ?
                        <React.Fragment>
                            <Route path='/create' element={<Create/>} />
                            <Route path='/create/create-collection' element={<CreateCollection/>} />
                            <Route path='/create/select-collection' element={<SelectCollection/>} />
                            <Route path='/create/massive-pre-mint-nft' element={<PreMintMassive/>} />
                            <Route path='/create/edit-collection' element={<EditCollection/>} />
                        </React.Fragment> : null
                        }
                        <Route path='*' element={<Navigate to="/" replace />} />
                        <Route path='*' element={<div>Not found 404</div>} />
                    </RoutesMain>
                </ContainerNetwork>
            </Main>
        </BrowserRouter>

        </React.Fragment>
        
  );
};

export default Routes;
