export const collections = [
  {
      "primary_key": "project_key",
      "short_primary_key": "proj..._key",
      "full": false,
      "project_key": "X25319661BEB94D8EF97F3B40FF2156F950BA61D1A",
      "short_project_key": "X253...1D1A",
      "user_key": "0X59...6D5A",
      "short_user_key": "0X59...6D5A",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/Qmeg5aY8qmZDE4mKELtTveDbGtUfjuTvEVWRBYqqs7TKcV",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmNxeuKxo7sYSSfT5dsV56tWrFvPQWrRA6AaDyZrEHS71v",
      "transaction": "X25319661BEB94D8EF97F3B40FF2156F950BA61D1A",
      "domain": "media909test.art",
      "only_domain": true,
      "imported": true,
      "status": 1,
      "name": "Aves",
      "description": "Las aves son animales vertebrados, de sangre caliente, que caminan, saltan o se mantienen solo sobre las extremidades posteriores,3​ mientras que las extremidades anteriores han evolucionado hasta convertirse en alas que, al igual que muchas otras características anatómicas únicas, les permiten, en la mayoría de los casos, volar, si bien no todas vuelan. Tienen el cuerpo cubierto de plumas y, las aves sensu stricto, un pico córneo sin dientes. Para reproducirse ponen huevos que incuban hasta su eclosión.",
      "blockchain_name": "GOERLI",
      "is_video": false,
      "external_url": "",
      "user": {
          "primary_key": "main_key",
          "short_primary_key": "main..._key",
          "full": false,
          "main_key": "0X59...6D5A",
          "short_main_key": "0X59...6D5A",
          "domain": "media909test.art",
          "username": "TestingOne",
          "email": "",
          "about": "testing testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testing",
          "twitter": "https://twitter.com/ErickBe45112973",
          "facebook": "",
          "telegram": "",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "wallet_pic_url": "QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "banner_url": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "sign": "No key",
          "role": 1,
          "expert": false,
          "theme": 1,
          "homepage": "",
          "country": "MX",
          "registered": true,
          "first_seen": "2022-11-09 15:21:15.337457",
          "last_seen": "2022-11-09 15:21:15.337457",
          "thumbnail_hide": false,
          "wallet": "0X59...6D5A",
          "id": "0X59...6D5A",
          "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmQoxcddDYBhXYisdUpjCzsgD5pd5YurPLsy4wWeEwTzag",
          "banner_url_big": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "banner_resize": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "profile_pic_original": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_mini": "https://persea.mypinata.cloud/ipfs/QmTdtmNiiSFswc3HGgm6ivw7cS6RzYyFbVicb7rmesEwjR",
          "profile_pic_url_big": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_large": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_resize": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "balances": [],
          "games": [],
          "banner_original": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "followers": 0,
          "following": 0
      },
      "blockchain": {
          "primary_key": "name",
          "short_primary_key": "name...name",
          "full": false,
          "name": "GOERLI",
          "rpc": "https://eth-goerli.g.alchemy.com/v2/D_RBMkM7gFv-4wnT1IMOfkFui23YNJjf",
          "chainid": "0xA869",
          "decimals": 18,
          "explorer": "https://snowtrace.io/",
          "symbol": "Avax",
          "icon_sm": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false,
          "thumbnail_hide": false
      },
      "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmRiux6EHDwfa2baBJ59QF99cMgsQYHRGj7DtekKa3ZMvx",
      "banner_url_big": "https://persea.mypinata.cloud/ipfs/QmNf5rVcYjycNVF5zKiERYYP6fFB8HufeTixwG2Buhn8B6",
      "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmS73jsc9ZRdEavTxQBdR3TjZHbTy8V6bCSXXGCd8MmUiq",
      "banner_resize": "https://persea.mypinata.cloud/ipfs/QmNf5rVcYjycNVF5zKiERYYP6fFB8HufeTixwG2Buhn8B6",
      "profile_pic_original": "",
      "profile_pic_url_mini": "",
      "profile_pic_url_big": "",
      "profile_pic_url_large": "",
      "profile_pic_url_resize": "",
      "profile_pic_url": "",
      "thumb_url_original": "https://persea.mypinata.cloud/ipfs/Qmeg5aY8qmZDE4mKELtTveDbGtUfjuTvEVWRBYqqs7TKcV",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmbfhsrGd9cQ4kqg6okfCvx5DJh4MXoQFzGxzZ9J43oC6v",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmPWr5kPEH1rAn2bgB5ve9aagQ6LhB5PkSvAPWYgL782XL",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/Qmeg5aY8qmZDE4mKELtTveDbGtUfjuTvEVWRBYqqs7TKcV",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmPWr5kPEH1rAn2bgB5ve9aagQ6LhB5PkSvAPWYgL782XL",
      "thumb_gif": "",
      "project_key_format": "X253...1D1A",
      "banner_original": "https://persea.mypinata.cloud/ipfs/QmNxeuKxo7sYSSfT5dsV56tWrFvPQWrRA6AaDyZrEHS71v",
      "banner_url_collection": "https://persea.mypinata.cloud/ipfs/QmS73jsc9ZRdEavTxQBdR3TjZHbTy8V6bCSXXGCd8MmUiq",
      "nfts": [],
      "categories": [],
      "tags": [],
      "offers": [],
      "owner": "0X59...6D5A",
      "comments": [],
      "collection_of": 0,
      "minted": 0,
      "sales": 0,
      "floor_price": 0,
      "owned_by": [],
      "reveal": {
          "primary_key": "project_key",
          "short_primary_key": "proj..._key",
          "full": false,
          "project_key": "X25319661BEB94D8EF97F3B40FF2156F950BA61D1A",
          "short_project_key": "X253...1D1A",
          "reveal_type": "mint",
          "reveal_date": "2022-11-16 18:53:10.333999",
          "is_ready": true,
          "confirmed": false,
          "sale_date": 1668624790.333999,
          "reveal_date_time": 1668624790.333999,
          "price": 0,
          "collection_of": 0,
          "minted": 0
      }
  },
  {
      "primary_key": "project_key",
      "short_primary_key": "proj..._key",
      "full": false,
      "project_key": "XF2D5A10B71809FE297A376D5E96843DD97F63090C",
      "short_project_key": "XF2D...090C",
      "user_key": "0X59...6D5A",
      "short_user_key": "0X59...6D5A",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/Qmcy3cG6aU814aTj2b34x8M5dqZXs1B7geQfz4evMJTiMu",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "transaction": "XF2D5A10B71809FE297A376D5E96843DD97F63090C",
      "domain": "media909test.art",
      "only_domain": true,
      "imported": true,
      "status": 1,
      "name": "Cafe",
      "description": "El café es la bebida que se obtiene a partir de los granos tostados y molidos de los frutos de la planta del café; es altamente estimulante por su contenido de cafeína, ​ una sustancia psicoactiva",
      "blockchain_name": "GOERLI",
      "is_video": false,
      "external_url": "",
      "user": {
          "primary_key": "main_key",
          "short_primary_key": "main..._key",
          "full": false,
          "main_key": "0X59...6D5A",
          "short_main_key": "0X59...6D5A",
          "domain": "media909test.art",
          "username": "TestingOne",
          "email": "",
          "about": "testing testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testing",
          "twitter": "https://twitter.com/ErickBe45112973",
          "facebook": "",
          "telegram": "",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "wallet_pic_url": "QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "banner_url": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "sign": "No key",
          "role": 1,
          "expert": false,
          "theme": 1,
          "homepage": "",
          "country": "MX",
          "registered": true,
          "first_seen": "2022-11-09 15:21:15.337457",
          "last_seen": "2022-11-09 15:21:15.337457",
          "thumbnail_hide": false,
          "wallet": "0X59...6D5A",
          "id": "0X59...6D5A",
          "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmQoxcddDYBhXYisdUpjCzsgD5pd5YurPLsy4wWeEwTzag",
          "banner_url_big": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "banner_resize": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "profile_pic_original": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_mini": "https://persea.mypinata.cloud/ipfs/QmTdtmNiiSFswc3HGgm6ivw7cS6RzYyFbVicb7rmesEwjR",
          "profile_pic_url_big": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_large": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_resize": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "balances": [],
          "games": [],
          "banner_original": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "followers": 0,
          "following": 0
      },
      "blockchain": {
          "primary_key": "name",
          "short_primary_key": "name...name",
          "full": false,
          "name": "GOERLI",
          "rpc": "https://eth-goerli.g.alchemy.com/v2/D_RBMkM7gFv-4wnT1IMOfkFui23YNJjf",
          "chainid": "0xA869",
          "decimals": 18,
          "explorer": "https://snowtrace.io/",
          "symbol": "Avax",
          "icon_sm": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false,
          "thumbnail_hide": false
      },
      "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmY7C1jufvW96zw1NjSC5rvWYXaWS7cEF3DuuGAJEY9A5t",
      "banner_url_big": "https://persea.mypinata.cloud/ipfs/QmWGLbwvPSJEYt13RkaFHksvt6adKoqEJMjDSvujBRMs45",
      "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmP924YyyeznYnjTSAWSJbZwkSwwfd5aaMGt8n4oGYWhMW",
      "banner_resize": "https://persea.mypinata.cloud/ipfs/QmWGLbwvPSJEYt13RkaFHksvt6adKoqEJMjDSvujBRMs45",
      "profile_pic_original": "",
      "profile_pic_url_mini": "",
      "profile_pic_url_big": "",
      "profile_pic_url_large": "",
      "profile_pic_url_resize": "",
      "profile_pic_url": "",
      "thumb_url_original": "https://persea.mypinata.cloud/ipfs/Qmcy3cG6aU814aTj2b34x8M5dqZXs1B7geQfz4evMJTiMu",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmRyzvzYZkp6gDQ6yGEZf5pzZ9NXzFxCamMReDkRd9SgN8",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmWhaYQGAr844iVR7rZDaH6diSUjCsmEo3yB58nHfhoqQA",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/Qmcy3cG6aU814aTj2b34x8M5dqZXs1B7geQfz4evMJTiMu",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmWhaYQGAr844iVR7rZDaH6diSUjCsmEo3yB58nHfhoqQA",
      "thumb_gif": "",
      "project_key_format": "XF2D...090C",
      "banner_original": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "banner_url_collection": "https://persea.mypinata.cloud/ipfs/QmP924YyyeznYnjTSAWSJbZwkSwwfd5aaMGt8n4oGYWhMW",
      "nfts": [],
      "categories": [],
      "tags": [],
      "offers": [],
      "owner": "0X59...6D5A",
      "comments": [],
      "collection_of": 30,
      "minted": 0,
      "sales": 0,
      "floor_price": 0.00001,
      "owned_by": [],
      "reveal": {
          "primary_key": "project_key",
          "short_primary_key": "proj..._key",
          "full": false,
          "project_key": "XF2D5A10B71809FE297A376D5E96843DD97F63090C",
          "short_project_key": "XF2D...090C",
          "reveal_type": "at_date",
          "reveal_date": "2022-11-14 17:23:37.332982",
          "is_ready": true,
          "confirmed": true,
          "sale_date": 1668446617.332982,
          "reveal_date_time": 1668446617.332982,
          "price": 0.00001,
          "usd_eth": 1275,
          "collection_of": 30,
          "minted": 0
      }
  },
  {
      "primary_key": "project_key",
      "short_primary_key": "proj..._key",
      "full": false,
      "project_key": "XF2D5A10B71809FE297A376D5E96843DD97F63090MNO",
      "short_project_key": "XF2D...0MNO",
      "user_key": "0X59...6D5A",
      "short_user_key": "0X59...6D5A",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmYoCK2UJuY6UPAeeqYsaRzWdPAHAFyhg5QCi5W8jYH61N",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "transaction": "XF2D5A10B71809FE297A376D5E96843DD97F63090MNO",
      "domain": "media909test.art",
      "only_domain": true,
      "imported": true,
      "status": 1,
      "name": "Marvel no oficial",
      "description": "testing testing data data testing",
      "blockchain_name": "GOERLI",
      "is_video": false,
      "external_url": "",
      "user": {
          "primary_key": "main_key",
          "short_primary_key": "main..._key",
          "full": false,
          "main_key": "0X59...6D5A",
          "short_main_key": "0X59...6D5A",
          "domain": "media909test.art",
          "username": "TestingOne",
          "email": "",
          "about": "testing testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testing",
          "twitter": "https://twitter.com/ErickBe45112973",
          "facebook": "",
          "telegram": "",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "wallet_pic_url": "QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "banner_url": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "sign": "No key",
          "role": 1,
          "expert": false,
          "theme": 1,
          "homepage": "",
          "country": "MX",
          "registered": true,
          "first_seen": "2022-11-09 15:21:15.337457",
          "last_seen": "2022-11-09 15:21:15.337457",
          "thumbnail_hide": false,
          "wallet": "0X59...6D5A",
          "id": "0X59...6D5A",
          "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmQoxcddDYBhXYisdUpjCzsgD5pd5YurPLsy4wWeEwTzag",
          "banner_url_big": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "banner_resize": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "profile_pic_original": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_mini": "https://persea.mypinata.cloud/ipfs/QmTdtmNiiSFswc3HGgm6ivw7cS6RzYyFbVicb7rmesEwjR",
          "profile_pic_url_big": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_large": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_resize": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "balances": [],
          "games": [],
          "banner_original": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "followers": 0,
          "following": 0
      },
      "blockchain": {
          "primary_key": "name",
          "short_primary_key": "name...name",
          "full": false,
          "name": "GOERLI",
          "rpc": "https://eth-goerli.g.alchemy.com/v2/D_RBMkM7gFv-4wnT1IMOfkFui23YNJjf",
          "chainid": "0xA869",
          "decimals": 18,
          "explorer": "https://snowtrace.io/",
          "symbol": "Avax",
          "icon_sm": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false,
          "thumbnail_hide": false
      },
      "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmY7C1jufvW96zw1NjSC5rvWYXaWS7cEF3DuuGAJEY9A5t",
      "banner_url_big": "https://persea.mypinata.cloud/ipfs/QmWGLbwvPSJEYt13RkaFHksvt6adKoqEJMjDSvujBRMs45",
      "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmP924YyyeznYnjTSAWSJbZwkSwwfd5aaMGt8n4oGYWhMW",
      "banner_resize": "https://persea.mypinata.cloud/ipfs/QmWGLbwvPSJEYt13RkaFHksvt6adKoqEJMjDSvujBRMs45",
      "profile_pic_original": "",
      "profile_pic_url_mini": "",
      "profile_pic_url_big": "",
      "profile_pic_url_large": "",
      "profile_pic_url_resize": "",
      "profile_pic_url": "",
      "thumb_url_original": "https://persea.mypinata.cloud/ipfs/QmYoCK2UJuY6UPAeeqYsaRzWdPAHAFyhg5QCi5W8jYH61N",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmWuZpwosyqhdXvGCPYGyAKouFH78YGcY9fpr3FdHhyQJp",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmRJig5QBaXjSQjUwXgGukzAtMho17oC36WLkbFCqmv3a7",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmYoCK2UJuY6UPAeeqYsaRzWdPAHAFyhg5QCi5W8jYH61N",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmRJig5QBaXjSQjUwXgGukzAtMho17oC36WLkbFCqmv3a7",
      "thumb_gif": "",
      "project_key_format": "XF2D...0MNO",
      "banner_original": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "banner_url_collection": "https://persea.mypinata.cloud/ipfs/QmP924YyyeznYnjTSAWSJbZwkSwwfd5aaMGt8n4oGYWhMW",
      "nfts": [],
      "categories": [],
      "tags": [],
      "offers": [],
      "owner": "0X59...6D5A",
      "comments": [],
      "collection_of": 30,
      "minted": 0,
      "sales": 0,
      "floor_price": 1,
      "owned_by": [],
      "reveal": {
          "primary_key": "project_key",
          "short_primary_key": "proj..._key",
          "full": false,
          "project_key": "XF2D5A10B71809FE297A376D5E96843DD97F63090MNO",
          "short_project_key": "XF2D...0MNO",
          "reveal_type": "mint",
          "reveal_date": "2022-11-14 16:57:18.404086",
          "is_ready": true,
          "confirmed": false,
          "sale_date": 1668445038.404086,
          "reveal_date_time": 1668445038.404086,
          "price": 1,
          "usd_eth": 1275,
          "collection_of": 30,
          "minted": 0
      }
  },
  {
      "primary_key": "project_key",
      "short_primary_key": "proj..._key",
      "full": false,
      "project_key": "0XE26A33F7ECFFF1F831A2C40FF551BDB76D3AB6F3",
      "short_project_key": "0XE2...B6F3",
      "user_key": "0X59...6D5A",
      "short_user_key": "0X59...6D5A",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmQ87cSQtF1b3jjdDuAQbVinmytWW7mAwWrAwaTybsZtUU",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "transaction": "0XE26A33F7ECFFF1F831A2C40FF551BDB76D3AB6F3",
      "domain": "media909test.art",
      "only_domain": true,
      "imported": true,
      "status": 1,
      "name": "Cars 1",
      "description": "testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing ",
      "blockchain_name": "GOERLI",
      "is_video": false,
      "external_url": "",
      "user": {
          "primary_key": "main_key",
          "short_primary_key": "main..._key",
          "full": false,
          "main_key": "0X59...6D5A",
          "short_main_key": "0X59...6D5A",
          "domain": "media909test.art",
          "username": "TestingOne",
          "email": "",
          "about": "testing testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testing",
          "twitter": "https://twitter.com/ErickBe45112973",
          "facebook": "",
          "telegram": "",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "wallet_pic_url": "QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "banner_url": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "sign": "No key",
          "role": 1,
          "expert": false,
          "theme": 1,
          "homepage": "",
          "country": "MX",
          "registered": true,
          "first_seen": "2022-11-09 15:21:15.337457",
          "last_seen": "2022-11-09 15:21:15.337457",
          "thumbnail_hide": false,
          "wallet": "0X59...6D5A",
          "id": "0X59...6D5A",
          "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmQoxcddDYBhXYisdUpjCzsgD5pd5YurPLsy4wWeEwTzag",
          "banner_url_big": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "banner_resize": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "profile_pic_original": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_mini": "https://persea.mypinata.cloud/ipfs/QmTdtmNiiSFswc3HGgm6ivw7cS6RzYyFbVicb7rmesEwjR",
          "profile_pic_url_big": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_large": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_resize": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "balances": [],
          "games": [],
          "banner_original": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "followers": 0,
          "following": 0
      },
      "blockchain": {
          "primary_key": "name",
          "short_primary_key": "name...name",
          "full": false,
          "name": "GOERLI",
          "rpc": "https://eth-goerli.g.alchemy.com/v2/D_RBMkM7gFv-4wnT1IMOfkFui23YNJjf",
          "chainid": "0xA869",
          "decimals": 18,
          "explorer": "https://snowtrace.io/",
          "symbol": "Avax",
          "icon_sm": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false,
          "thumbnail_hide": false
      },
      "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "banner_url_big": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "banner_resize": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "profile_pic_original": "",
      "profile_pic_url_mini": "",
      "profile_pic_url_big": "",
      "profile_pic_url_large": "",
      "profile_pic_url_resize": "",
      "profile_pic_url": "",
      "thumb_url_original": "https://persea.mypinata.cloud/ipfs/QmQ87cSQtF1b3jjdDuAQbVinmytWW7mAwWrAwaTybsZtUU",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmQ87cSQtF1b3jjdDuAQbVinmytWW7mAwWrAwaTybsZtUU",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmQ87cSQtF1b3jjdDuAQbVinmytWW7mAwWrAwaTybsZtUU",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmQ87cSQtF1b3jjdDuAQbVinmytWW7mAwWrAwaTybsZtUU",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmQ87cSQtF1b3jjdDuAQbVinmytWW7mAwWrAwaTybsZtUU",
      "thumb_gif": "",
      "project_key_format": "0XE2...B6F3",
      "banner_original": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "banner_url_collection": "https://persea.mypinata.cloud/ipfs/QmQqCJoR8NvSjBGcbTE9nQVhSrukTVmbmrEdg6DZQ72GCQ",
      "nfts": [],
      "categories": [],
      "tags": [],
      "offers": [],
      "owner": "0X59...6D5A",
      "comments": [],
      "collection_of": 70,
      "minted": 0,
      "sales": 0,
      "floor_price": 0.0001,
      "owned_by": [],
      "reveal": {
          "primary_key": "project_key",
          "short_primary_key": "proj..._key",
          "full": false,
          "project_key": "0XE26A33F7ECFFF1F831A2C40FF551BDB76D3AB6F3",
          "short_project_key": "0XE2...B6F3",
          "reveal_type": "mint",
          "reveal_date": "2022-11-10 21:19:58.004410",
          "is_ready": false,
          "confirmed": false,
          "sale_date": 1668115198.00441,
          "reveal_date_time": 1668115198.00441,
          "price": 0.0001,
          "usd_eth": 1275,
          "collection_of": 70,
          "minted": 0
      }
  },
  {
      "primary_key": "project_key",
      "short_primary_key": "proj..._key",
      "full": false,
      "project_key": "0X3F38C964F9A348FE8626D51FDEB9FB0078BBB87C",
      "short_project_key": "0X3F...B87C",
      "user_key": "0X59...6D5A",
      "short_user_key": "0X59...6D5A",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmRk2FRa5VttprSoyUc4GQsTXnAmkr6g3wkLKpsEMJbXqZ",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "transaction": "0X3F38C964F9A348FE8626D51FDEB9FB0078BBB87C",
      "domain": "media909test.art",
      "only_domain": true,
      "imported": true,
      "status": 1,
      "name": "thumb random",
      "description": "thumb randomthumb randomthumb randomthumb randomthumb randomthumb randomthumb randomthumb randomthumb random",
      "blockchain_name": "GOERLI",
      "is_video": false,
      "external_url": "",
      "user": {
          "primary_key": "main_key",
          "short_primary_key": "main..._key",
          "full": false,
          "main_key": "0X59...6D5A",
          "short_main_key": "0X59...6D5A",
          "domain": "media909test.art",
          "username": "TestingOne",
          "email": "",
          "about": "testing testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testingtesting testing",
          "twitter": "https://twitter.com/ErickBe45112973",
          "facebook": "",
          "telegram": "",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "wallet_pic_url": "QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "banner_url": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "sign": "No key",
          "role": 1,
          "expert": false,
          "theme": 1,
          "homepage": "",
          "country": "MX",
          "registered": true,
          "first_seen": "2022-11-09 15:21:15.337457",
          "last_seen": "2022-11-09 15:21:15.337457",
          "thumbnail_hide": false,
          "wallet": "0X59...6D5A",
          "id": "0X59...6D5A",
          "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmQoxcddDYBhXYisdUpjCzsgD5pd5YurPLsy4wWeEwTzag",
          "banner_url_big": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "banner_resize": "https://persea.mypinata.cloud/ipfs/Qmd8E9hNvnipWhrFZ2crkGbjzvucn9skw74o6XSeFSh5L1",
          "profile_pic_original": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_mini": "https://persea.mypinata.cloud/ipfs/QmTdtmNiiSFswc3HGgm6ivw7cS6RzYyFbVicb7rmesEwjR",
          "profile_pic_url_big": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_large": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "profile_pic_url_resize": "https://persea.mypinata.cloud/ipfs/QmPasGsqQT6bR1j2FCN7iqKP4aqDPPVLMJhFbst9sKDJ38",
          "balances": [],
          "games": [],
          "banner_original": "https://persea.mypinata.cloud/ipfs/QmWcYADzYgHje92h7SxfpBA9i4SrDMhWHy8maWeU64FYRd",
          "followers": 0,
          "following": 0
      },
      "blockchain": {
          "primary_key": "name",
          "short_primary_key": "name...name",
          "full": false,
          "name": "GOERLI",
          "rpc": "https://eth-goerli.g.alchemy.com/v2/D_RBMkM7gFv-4wnT1IMOfkFui23YNJjf",
          "chainid": "0xA869",
          "decimals": 18,
          "explorer": "https://snowtrace.io/",
          "symbol": "Avax",
          "icon_sm": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false,
          "thumbnail_hide": false
      },
      "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "banner_url_big": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "banner_resize": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "profile_pic_original": "",
      "profile_pic_url_mini": "",
      "profile_pic_url_big": "",
      "profile_pic_url_large": "",
      "profile_pic_url_resize": "",
      "profile_pic_url": "",
      "thumb_url_original": "https://persea.mypinata.cloud/ipfs/QmRk2FRa5VttprSoyUc4GQsTXnAmkr6g3wkLKpsEMJbXqZ",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmepK6SZRaSQqzjjDZaua786Ro21jJK2qsrHWVM3mVVeUs",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmRRgvybeEy4MkvmdJbyUGtRZDLMzHLe3tJCpeEbeigZ3D",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmRk2FRa5VttprSoyUc4GQsTXnAmkr6g3wkLKpsEMJbXqZ",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmRk2FRa5VttprSoyUc4GQsTXnAmkr6g3wkLKpsEMJbXqZ",
      "thumb_gif": "",
      "project_key_format": "0X3F...B87C",
      "banner_original": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "banner_url_collection": "https://persea.mypinata.cloud/ipfs/QmYH9YLrsmhUAhX4dKzycBvWPVrZmh6rCmv25kHtEUHaJV",
      "nfts": [],
      "categories": [],
      "tags": [],
      "offers": [],
      "owner": "0X59...6D5A",
      "comments": [],
      "collection_of": 10,
      "minted": 0,
      "sales": 0,
      "floor_price": 0.00001,
      "owned_by": [],
      "reveal": {
          "primary_key": "project_key",
          "short_primary_key": "proj..._key",
          "full": false,
          "project_key": "0X3F38C964F9A348FE8626D51FDEB9FB0078BBB87C",
          "short_project_key": "0X3F...B87C",
          "reveal_type": "mint",
          "reveal_date": "2022-11-09 15:50:59.462852",
          "is_ready": false,
          "confirmed": false,
          "sale_date": 1668009059.462852,
          "reveal_date_time": 1668009059.462852,
          "price": 0.00001,
          "usd_eth": 1275,
          "collection_of": 10,
          "minted": 0
      }
  },
  {
      "primary_key": "project_key",
      "short_primary_key": "proj..._key",
      "full": false,
      "project_key": "0X906A9048599E1C45B851CDECED7E9DAC554DE9B2",
      "short_project_key": "0X90...E9B2",
      "user_key": "0XA5...5B22",
      "short_user_key": "0XA5...5B22",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmRttkXjTiSX2eTgXcVRAGyQv9V2kuJpigThHgAeu5dmc4",
      "banner_url": "https://persea.mypinata.cloud/ipfs/Qmdy8ALYJc5SMtWxxm7Kqzk3g7g9gLfKyNGwJcmeuCN3MK",
      "transaction": "0X906A9048599E1C45B851CDECED7E9DAC554DE9B2",
      "domain": "media909test.art",
      "only_domain": true,
      "imported": true,
      "status": 1,
      "name": "TESTING",
      "description": "TESTING",
      "blockchain_name": "GOERLI",
      "is_video": false,
      "external_url": "",
      "user": {
          "primary_key": "main_key",
          "short_primary_key": "main..._key",
          "full": false,
          "main_key": "0XA5...5B22",
          "short_main_key": "0XA5...5B22",
          "domain": "media909test.art",
          "username": "0XA5461CBCF9C767264CC619BCF1AF3AAD083A5B22",
          "email": "",
          "about": "",
          "twitter": "",
          "facebook": "no",
          "telegram": "",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmPzxtWDmpq32ftGueZ6ZsonmNGF4imFvMoVkvkbr88Y5a",
          "wallet_pic_url": "QmPzxtWDmpq32ftGueZ6ZsonmNGF4imFvMoVkvkbr88Y5a",
          "banner_url": "",
          "sign": "No key",
          "role": 1,
          "expert": false,
          "theme": 1,
          "homepage": "no",
          "country": "MX",
          "registered": true,
          "first_seen": "2022-11-04 23:33:42.499442",
          "last_seen": "2022-11-04 23:33:42.499442",
          "thumbnail_hide": false,
          "wallet": "0XA5...5B22",
          "id": "0XA5...5B22",
          "banner_url_mini": "",
          "banner_url_big": "",
          "banner_url_large": "",
          "banner_resize": "",
          "profile_pic_original": "https://persea.mypinata.cloud/ipfs/QmPzxtWDmpq32ftGueZ6ZsonmNGF4imFvMoVkvkbr88Y5a",
          "profile_pic_url_mini": "https://persea.mypinata.cloud/ipfs/QmSjDoTqG1Dmkegb3j4CSuse6ziuxbeMuBwCpTTtb1LTLt",
          "profile_pic_url_big": "https://persea.mypinata.cloud/ipfs/QmPzxtWDmpq32ftGueZ6ZsonmNGF4imFvMoVkvkbr88Y5a",
          "profile_pic_url_large": "https://persea.mypinata.cloud/ipfs/QmPzxtWDmpq32ftGueZ6ZsonmNGF4imFvMoVkvkbr88Y5a",
          "profile_pic_url_resize": "https://persea.mypinata.cloud/ipfs/QmPzxtWDmpq32ftGueZ6ZsonmNGF4imFvMoVkvkbr88Y5a",
          "balances": [],
          "games": [],
          "followers": 0,
          "following": 0
      },
      "blockchain": {
          "primary_key": "name",
          "short_primary_key": "name...name",
          "full": false,
          "name": "GOERLI",
          "rpc": "https://eth-goerli.g.alchemy.com/v2/D_RBMkM7gFv-4wnT1IMOfkFui23YNJjf",
          "chainid": "0xA869",
          "decimals": 18,
          "explorer": "https://snowtrace.io/",
          "symbol": "Avax",
          "icon_sm": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false,
          "thumbnail_hide": false
      },
      "banner_url_mini": "https://persea.mypinata.cloud/ipfs/QmPdMEDxar6UtgpFKktEdhdwbFHvChGcbPaekv6P1o3Cn2",
      "banner_url_big": "https://persea.mypinata.cloud/ipfs/QmTiqgFCfa9p6wQPkNZoCkB33qERYzLRV216BAsseFgcpb",
      "banner_url_large": "https://persea.mypinata.cloud/ipfs/QmNptMEZSWTFbi6KVNLkeCnJt5PVjQz9cttZWqSfTeLUxv",
      "banner_resize": "https://persea.mypinata.cloud/ipfs/QmTiqgFCfa9p6wQPkNZoCkB33qERYzLRV216BAsseFgcpb",
      "profile_pic_original": "",
      "profile_pic_url_mini": "",
      "profile_pic_url_big": "",
      "profile_pic_url_large": "",
      "profile_pic_url_resize": "",
      "profile_pic_url": "",
      "thumb_url_original": "https://persea.mypinata.cloud/ipfs/QmRttkXjTiSX2eTgXcVRAGyQv9V2kuJpigThHgAeu5dmc4",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmRkXHrjaF8U1vBuxj2wBmnFxPf89cm8AjPTDHSjmRPALN",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmaWrpydvDvUinxaWfpfkir8hdTnLxDPP171M9etHo5v6C",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmRttkXjTiSX2eTgXcVRAGyQv9V2kuJpigThHgAeu5dmc4",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmaWrpydvDvUinxaWfpfkir8hdTnLxDPP171M9etHo5v6C",
      "thumb_gif": "",
      "project_key_format": "0X90...E9B2",
      "banner_original": "https://persea.mypinata.cloud/ipfs/Qmdy8ALYJc5SMtWxxm7Kqzk3g7g9gLfKyNGwJcmeuCN3MK",
      "banner_url_collection": "https://persea.mypinata.cloud/ipfs/QmNptMEZSWTFbi6KVNLkeCnJt5PVjQz9cttZWqSfTeLUxv",
      "nfts": [],
      "categories": [],
      "tags": [],
      "offers": [],
      "owner": "0XA5...5B22",
      "comments": [],
      "collection_of": 5,
      "minted": 0,
      "sales": 0,
      "floor_price": 0.00001,
      "owned_by": [],
      "reveal": {
          "primary_key": "project_key",
          "short_primary_key": "proj..._key",
          "full": false,
          "project_key": "0X906A9048599E1C45B851CDECED7E9DAC554DE9B2",
          "short_project_key": "0X90...E9B2",
          "reveal_type": "at_date",
          "reveal_date": "2022-11-04 23:37:36.801393",
          "is_ready": true,
          "confirmed": true,
          "price": 0.00001,
          "usd_eth": 1275,
          "collection_of": 5,
          "minted": 0
      }
  }
]