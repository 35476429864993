import React, { useContext } from 'react'
import { Context } from 'hooks/WalletContext';
import Wallet from '../Wallet';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

const CustomNavLink = styled(NavLink)`
    text-decoration: none;
    color: #fff;
    border: none;
    background: '#fff';
    padding: 0px 0px;
`;


const DesktopBar = ({tabs}) => {
    const { t } = useTranslation("translate");
    const { data } = useContext(Context);
    const { openWallet, setOpenWallet } = useContext(DrawerMobileContext);

    const handleOpenWallet = () => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        setOpenWallet(!openWallet);
    }

    let temp = 22;
    if(temp == -22){
        console.log(tabs) 
    }
    return (
        <React.Fragment>
            {(data && data.userAccount && data.user && data.user.role > 1)  &&
            <Box 
                component={CustomNavLink} 
                style={{
                    color:"#F2F2F2",
                    background:"transparent"
                }}
                to='/create' 
                sx={{
                    fontSize:'0.975rem',
                    mt:'-3px',
                    textDecoration:'none',
                    fontWeight:'bold',
                    display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},opacity:'0.5',
                    '&:hover':{
                        opacity:'1'
                    }   
                }}
            >
                {t('top_bar.create')}
            </Box>}
            {data && data.userAccount &&
            <Box
                sx={{
                    display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},
                    color:'#D9D9D9',
                    cursor:'pointer',
                    opacity:'0.5',
                    '&:hover':{
                        opacity:'1'
                    } 
                }}
            >
                <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.4736 16.6397L16.9917 17.6677C17.2129 18.1064 17.7193 18.3175 18.1779 18.1412C19.5968 17.5956 22.5206 16.2997 23.8112 14.4703C23.9174 14.3198 23.9664 14.1402 23.9714 13.956C23.9728 13.9068 23.9743 13.8536 23.976 13.7966C24.0377 11.6653 24.2529 4.24048 20.3713 1.65309C19.133 0.827559 17.3259 0.286392 16.3215 0.029589C15.8846 -0.0821142 15.4382 0.135472 15.2365 0.538815L14.6562 1.69945C14.4868 2.03823 14.1405 2.25224 13.7618 2.25224H11.9835H10.2382C9.85946 2.25224 9.51319 2.03823 9.3438 1.69945L8.76348 0.538814C8.56181 0.135472 8.11538 -0.0821141 7.67848 0.029589C6.67407 0.286392 4.86704 0.827559 3.62874 1.65309C-0.252886 4.24048 -0.0377367 11.6653 0.0240216 13.7966C0.0256736 13.8536 0.0272158 13.9068 0.0285669 13.956C0.0336192 14.1402 0.0825771 14.3198 0.188762 14.4703C1.4817 16.3029 4.41348 17.6001 5.82957 18.1441C6.28507 18.319 6.78805 18.1117 7.01153 17.6779L7.54939 16.6339C7.78766 16.1714 8.34055 15.9722 8.82669 16.1575C11.3473 17.1182 12.6318 17.1169 15.1982 16.1535C15.6862 15.9703 16.239 16.1743 16.4736 16.6397ZM5.99204 9.44198C5.99204 10.7661 6.79729 11.8386 7.78947 11.8386C8.78166 11.8386 9.58691 10.7661 9.58691 9.44198C9.58691 8.11787 8.78166 7.0454 7.78947 7.0454C6.79729 7.0454 5.99204 8.11787 5.99204 9.44198ZM14.3801 9.44198C14.3801 10.7661 15.1853 11.8386 16.1775 11.8386C17.1697 11.8386 17.9749 10.7661 17.9749 9.44198C17.9749 8.11787 17.1697 7.0454 16.1775 7.0454C15.1853 7.0454 14.3801 8.11787 14.3801 9.44198Z" fill="white"/>
                </svg>
            </Box>
            }
            {data && data.userAccount &&
            <Box
                component={CustomNavLink}
                to={`/profile?address=${data.userAccount}`}
                sx={{
                    display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},
                    color:'#D9D9D9',
                    cursor:'pointer',
                    opacity:'0.5',
                    '&:hover':{
                        opacity:'1'
                    }
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.579 2C8.66407 2 6.30103 4.36304 6.30103 7.278C6.30103 10.193 8.66407 12.556 11.579 12.556C14.494 12.556 16.857 10.193 16.857 7.278C16.857 4.36304 14.494 2 11.579 2ZM13.806 15.283C12.3212 15.1528 10.8278 15.1528 9.34301 15.283C8.5339 15.3393 7.72974 15.4524 6.93651 15.6216C6.37391 15.7371 5.73753 15.9252 5.17139 16.2152C4.61058 16.5024 4.06837 16.9158 3.7725 17.5075L3.7693 17.514C3.59072 17.8845 3.49866 18.2907 3.50001 18.702C3.49966 19.1146 3.59116 19.5221 3.7679 19.895L3.76996 19.8992C4.06975 20.5164 4.61347 20.9322 5.18071 21.2151C5.74833 21.4983 6.3845 21.6714 6.94549 21.7812C7.72632 21.9559 8.5192 22.0716 9.31739 22.1272C9.62872 22.1854 9.92446 22.1854 10.1792 22.1854H10.1835C11.3904 22.2497 12.6004 22.2309 13.8048 22.129C14.6149 22.0735 15.4197 21.9572 16.2124 21.7812C16.7686 21.6715 17.4049 21.4984 17.9727 21.2151C18.5392 20.9325 19.0852 20.516 19.3805 19.8957C19.7384 19.1431 19.7385 18.2691 19.3806 17.5164C19.0853 16.8962 18.5393 16.4796 17.9735 16.196C17.405 15.9111 16.7675 15.7356 16.2121 15.6215C15.4189 15.453 14.6149 15.3399 13.806 15.283Z" fill="white"/>
                </svg>
            </Box>}
            <Box
                onClick={handleOpenWallet}
                sx={{
                    display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},
                    color:'#D9D9D9',
                    cursor:'pointer',
                    opacity:'0.5',
                    '&:hover':{
                        opacity:'1'
                    } 
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.6 3.20001C22.9255 3.20001 24 4.27453 24 5.60001V8.00001H0V5.60001C0 4.27453 1.07452 3.20001 2.4 3.20001H21.6Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 9.60001V18.4C0 19.7255 1.07452 20.8 2.4 20.8L21.6 20.8C22.9255 20.8 24 19.7255 24 18.4V9.60001H0ZM3.2 16H12.8V14.4H3.2V16ZM20.8 16H16V14.4H20.8V16Z" fill="white"/>
                </svg>
            </Box>
            <Wallet />
        </React.Fragment>
    )
}

DesktopBar.propTypes = {
    tabs: PropTypes.array,
}

export default DesktopBar