import React, { useContext, useState } from 'react';
import { Box, Button, CardMedia, CircularProgress, Divider } from "@mui/material";
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import { StatusTx } from 'hooks/StatusTxContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { payableMint } from 'services/ERC721/payableMint';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { totalLeft } from 'services/ERC721/totalLeft';
import { Link } from 'react-router-dom';
import { Context } from 'hooks/WalletContext';
import styled from 'styled-components';
import ModalSwitchNetwork from 'components/ModalSwitchNetwork';

const PublicMint = ({ collection, wallet, reserve ,activeNFTPay, price}) => {
    const { t } = useTranslation("translate");
    const { setOpenWallet } = useContext(DrawerMobileContext);
    const { data } = useContext(Context)
    const { setStatusTx, statusTx } = useContext(StatusTx);
    const [errorTx, setErrorTx] = useState(null);
    const [successTx, setSuccessTx] = useState(null);
    const [hideElement, setHideElement] = useState(null);
    const [msgTx, setMsgTx] = useState(null);
    const [openChangeNetwork, setOpenChangeNetwork] = useState(false);
    console.log('collection', collection)
    const StyledIframe = styled.iframe`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
  height: 100%;
  visibility: hidden;
  display: none;
`;

    const openWinterCheckout  = async() => {
        let iframe = document.getElementById("winter-checkout");
        document.body.appendChild(iframe);
        iframe.style.visibility = "visible";
        document.getElementById("winter-checkout").style.display = "inline";

        window.addEventListener('message', (event) => {
            console.log(`Received message: ${event.data}`);
            if (event.data === "closeWinterCheckoutModal") {
               // properly close the winter modal so it can be opened again
              document.getElementById("winter-checkout").style.visibility = "hidden";
           document.getElementById("winter-checkout").style.display = "none";
            } else if (event.data.name === 'successfulWinterCheckout') {
              // Successfully checked out. This event contains information 
              // you can use to continue the flow: 
              console.log(event.data.transactionhash) // do what you need with the txhash here! 
              console.log(event.data.email) // email that the user bought an NFT with
            }
        });
        document.getElementById("winter-checkout").addEventListener('close', () => {
            let iframe = document.getElementById("winter-checkout");
            if (iframe) {
                iframe.style.visibility = "hidden";
            }
        });
    }

    const handleBuyCollection = async () => {
        setStatusTx(true);
        setMsgTx(null);
        setHideElement(true)
        try {
            let left =  await totalLeft(collection)
            if(left == 0) {
                window.location.reload();
            }
            console.log('data.chainId', data)
            if(data.chainIdHex != collection.blockchain.chainid) {
                setOpenChangeNetwork(true);
                return;
            }
            payableMint(wallet.provider, wallet.userAccount, collection.project_key, collection)
            .then(async(response) => {
                if (response && response.type == 0) {
                    setStatusTx(false);
                    setErrorTx(null);
                    setSuccessTx(null); 
                    setMsgTx(false);
                    setHideElement(false);
                    console.log('response type ::', response);
                } else {
                    console.log(response);
                    setStatusTx(false);
                    setErrorTx(null);
                    setSuccessTx(true);
                    setHideElement(true);
                    setMsgTx(response.transaction);
                    if ( await totalLeft(wallet.provider, collection.project_key) == 0) {
                        window.location.reload()
                    }
                }
            })
            .catch((error) => {
                setMsgTx(false)
                setStatusTx(false);
                setErrorTx("Please contact support with the administrator");
                setHideElement(false);
                console.error(error);
            })
        } catch (error) {
            console.error(error);
            setStatusTx(false);
            setSuccessTx(null);
            msgTx(false);
            setHideElement(false);
            setErrorTx("Please contact support with the administrator");
        }
    };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
                background: 'rgba(0, 0, 0, 0.25)',
                boxSizing: 'border-box',
                padding: '15px',
                borderRadius: '5px',
                mt: 2
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
            >
                <Box>{t("collection_buy_view.public_mint")}</Box>
                <Box display="none">Ended</Box>
            </Box>
            { collection && collection.blockchain &&
                <ModalSwitchNetwork open={openChangeNetwork} setOpen={setOpenChangeNetwork} project={collection} />
            }
            <Divider sx={{ width: '100%', mt: 1, mb: 1 }} />
            <Box
                width="100%"
                sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    flexDirection:{xs:'column',sm:'column',md:'column',lg:'row',xl:'row'},
                    '@media screen and (max-width: 350px)':{
                        flexDirection:'column'
                    }
                }}
            >
                {  collection.reveal &&
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="20px"
                        sx={{
                            width:'100%'
                        }}
                    >
                        { collection.reveal && price > 0 &&
                            <Box>
                                <Box>{t("collection_buy_view.price")}</Box>
                                <Box display="flex" alignItems="center" gap="5px">
                                    <CardMedia component="img" src="eth.png" alt="currency" sx={{ width: '12px' }} />
                                    <span>{price}</span>
                                </Box>
                            </Box>

                        }
                        <Box>
                            <Box>Items</Box>
                            <Box>{collection.collection_of }</Box>
                        </Box>
                        <Box>
                            <Box>{t("collection_buy_view.max_items")}</Box>
                            <Box>1</Box>
                        </Box>
                    </Box>
                }
                {Object.keys(collection.reveal).length > 0 && reserve > 0 && (
                    <Box
                        gap="5px"
                        sx={{
                            width:'100%',
                            display:'flex',
                            justifyContent:{xs:'center',sm:'flex-end',md:'flex-end',lg:'flex-end',xl:'flex-end'},
                            alignItems:'center',
                            flexDirection:{xs:'row',sm:'row',md:'row',lg:'row',xl:'row'},
                            mt:{xs:'10px',sm:'10px',md:'10px',lg:'10px',xl:'10px'},
                        }}
                    >
                        {wallet && wallet.userAccount  ? (
                            <>
                                {
                                (wallet && wallet.userAccount && wallet.user ) ?
                                <React.Fragment>
                                    {!statusTx && !errorTx && !successTx && (
                                        <Button
                                            disabled={hideElement}
                                            type="button"
                                            onClick={handleBuyCollection}
                                            sx={{
                                                display: !hideElement ? 'initial' : 'none',
                                                color:'#fff',
                                                "&:hover":{
                                                    color:'#00FD90',
                                                    border:'1px solid #00FD90'
                                                },
                                                '@media screen and (max-width: 350px)':{
                                                    width:'100%'
                                                },lineHeight:1.2
                                            }}
                                        >
                                            {t("collection_buy_view.buy_with_crypto")}
                                        </Button>
                                    )}
                                    {statusTx && !errorTx && !successTx && (
                                        <CircularProgress sx={{ color: '#fff' }} />
                                    )}
                                    {!statusTx && errorTx && !successTx && (
                                        <Box>error</Box>
                                    )}
                                    {!statusTx && !errorTx && successTx && msgTx && (
                                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ mt: 2 }}>
                                            <CheckCircleIcon fontSize="large" sx={{ color: '#4aa521' }} />
                                            <a 
                                                href={`${collection.blockchain.explorer}/tx/${msgTx}`}
                                                style={{ textDecoration:'none', color: '#fff', height:'100%', textAlign: 'center' }} 
                                                target="_blank" rel="noreferrer"
                                            >
                                                {t("collection_buy_view.buy_success")}
                                                <span style={{ marginLeft: '8px'}}>
                                                    {(msgTx).substring(0,5)}...{(msgTx).substring(37,42)}
                                                </span><br/>
                                                <Box
                                                    component={Link}
                                                    to={`/profile?address=${String(data.userAccount).toUpperCase()}`}
                                                    sx={{
                                                        textDecoration:'none',
                                                        color:'#fff',
                                                        "&:hover":{
                                                            color:'#00FD90'
                                                        }
                                                    }}
                                                >
                                                    <small>
                                                        {t("collection_buy_view.go_to_profile")}
                                                    </small>
                                                </Box>
                                                
                                            </a>
                                        </Box>
                                    )}
                                </React.Fragment>
                                :
                                <Button
                                    type="button"
                                    onClick={() => setOpenWallet(true)}
                                    sx={{
                                        background:'#00FD90',
                                        color:'#000',
                                        "&:hover":{
                                            color:'#fff',
                                            border:'1px solid #fff'
                                        },
                                        '@media screen and (max-width: 350px)':{
                                            width:'100%'
                                        },lineHeight:1.2
                                    }}
                                >
                                    {t("collection_buy_view.register_user")}
                                </Button>
                                }
                            </>
                        )
                        : 
                        (
                            <Button
                                type="button"
                                onClick={() => setOpenWallet(true)}
                                sx={{
                                    background:'#00FD90',
                                    color:'#000',
                                    "&:hover":{
                                        color:'#fff',
                                        border:'1px solid #fff'
                                    },
                                    '@media screen and (max-width: 350px)':{
                                        width:'100%'
                                    },lineHeight:1.2
                                }}
                            >
                                {t("collection_buy_view.connect_wallet")}
                            </Button>
                        )}
                        {
                            activeNFTPay && activeNFTPay.activePay &&
                            <>
                                <Button
                                    disabled={hideElement}
                                    type="button"
                                    onClick={()=>openWinterCheckout ()}
                                    sx={{
                                        display: !hideElement ? 'initial' : 'none',
                                        background:'#00FD90',
                                        color:'#000',
                                        "&:hover":{
                                            color:'#00FD90',
                                            border:'1px solid #00FD90'
                                        },
                                        '@media screen and (max-width: 350px)':{
                                            width:'100%'
                                        },lineHeight:1.2
                                    }}
                                >
                                    {t("collection_buy_view.buy_with_credit_card")}
                                </Button>
                                <StyledIframe  id="winter-checkout" src={activeNFTPay.urlPayments} data-allowtransparency="true"></StyledIframe >
                            </>
                        }
                    </Box>
                )}
            </Box>
        </Box>
    );
};

PublicMint.propTypes = {
    collection: PropTypes.object,
    reserve: PropTypes.number,
    wallet: PropTypes.object,
    activeNFTPay: PropTypes.any,
    price: PropTypes.number
};

export default PublicMint;
