import React from 'react'
import TopBar from './Topbar'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
const Main = ({ children }) => {
  
    return (
        <React.Fragment>
        <div id="bg">
            {/* <!-- SVG base --> */}
            <svg id="bg-svg" width="150%" height="150%" viewBox="0 0 800 800" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="vbc-bg" clipPath="url(#clip0_156_2336)">
                    <rect width="800" height="800" fill="#151515" />
                    <g id="Square">
                        <rect id="Rectangle 1974" x="-80" y="396.766" width="514.326" height="514.326" fill="#A457FF" />
                    </g>
                    <g id="Circle">
                        <circle id="Ellipse 1" cx="592.773" cy="223.443" r="257.163" transform="rotate(-90 592.773 223.443)"
                            fill="#0099FF" />
                    </g>
                    <g id="Triangle">
                        <path id="Polygon 1" d="M336.444 193.766L559.397 579.511H113.491L336.444 193.766Z" fill="#00FD90" />
                    </g>
                    <g id="Pentagon">
                        <path id="Polygon 2"
                            d="M233.671 -111L478.514 66.6951L384.992 354.212H82.349L-11.173 66.6951L233.671 -111Z"
                            fill="#FFFF00" />
                    </g>
                    <g id="Star">
                        <path id="Star 1"
                            d="M365.441 653.933L543.136 596.196V409.357L652.958 560.513L830.653 502.777L720.831 653.933L830.653 805.09L652.958 747.353L543.136 898.51V711.67L365.441 653.933Z"
                            fill="#F50537" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_156_2336">
                        <rect width="800" height="800" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            {/* <!-- Gradient --> */}
            <div id='bg-gradient'>
               
            </div>
            {/* <!-- Noise texture --> */}
            <div id="bg-noise"></div>

            <Box
                sx={{
                    zIndex:'2'
                }}
            >
                <TopBar />
                <Box
                    sx={{
                        width:'100%',
                        maxWidth:'100vw',
                        overflowX: 'hidden',
                        height:'calc(100vh - 65px)'
                    }}
                >
                    <Box sx={{width:'100%',height:'100%',overflow:'auto'}}>
                        {children}
                        <Box sx={{width:'100%',height:'30px'}}/>
                    </Box>
                </Box>
            </Box>
          
        </div>
            
        </React.Fragment>
    )
}

Main.propTypes = {
    children: PropTypes.node,
}

export default Main