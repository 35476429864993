import React,{createContext, useEffect, useState } from 'react'
import getConnection from 'services/connection'
import PropTypes from 'prop-types'

const defaultState = {
    userAccount: undefined,
    balance: '0.000',
    network: 'disconnected',
    chainID: 'NaN',
    provider: null,
    maticBalance: '0.00',
    viewEmailInput : false,
    user : null,
    sign_exchange : false,
    formatWMaticBalance: '0.00'
}
export const Context = createContext(defaultState)

const WalletProvider = ({children})=>{
    const [data, setData]=useState(defaultState)
    const [isLoading, setIsLoading] = useState(false);
    const [msg,setMsg] = useState('');
    const connection =(wallet)=>{
        getConnection(wallet,setMsg,setData,setIsLoading)
            .then(res=>setData(res))
            .catch((error) => {
                console.error('Error Context', error);
            });
    }

    let net = localStorage.getItem('network')
    let metamask = localStorage.getItem('isMetamask')
    let walletConnect = localStorage.getItem('walletConnect')

    useEffect(()=>{
        net = localStorage.getItem('network')
        metamask = localStorage.getItem('isMetamask')
        walletConnect = localStorage.getItem('walletConnect')
        if(net && metamask){
            setIsLoading(true)
            connection('metamask')
        }
        if(walletConnect){
            setIsLoading(true)
            connection('walletConnect')
        }
    },[])
    return(
        <Context.Provider value={{data, connection, isLoading, setIsLoading, setData,msg}}>
            {children}
        </Context.Provider>
    )
}

WalletProvider.propTypes = {
    children: PropTypes.node
}

export default WalletProvider