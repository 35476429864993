import React, { useContext } from 'react'
import { Box, CardMedia, Container, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Context } from 'hooks/WalletContext';
import { switchNetwork } from 'services/web3';
import { style } from './styles';
import EthIcon from 'assets/logos/eth.png'
import PropTypes from 'prop-types';

const ModalSwitchNetwork = ( {open, setOpen , project}) => {
    console.log('project ::',project)
    const { t } = useTranslation("translate");
    const { data }= useContext(Context)

    const handleChangeNetwork = () => {
        switchNetwork(data.provider,project.blockchain)
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            { project.blockchain &&
                <Box sx={style}>
                    <h3 style={{textAlign:'center'}}>{t("modal_switch_network.title")} {project.blockchain.blockchain_name}</h3>
                    <Container maxWidth="xs" onClick={handleChangeNetwork} sx={{ cursor: 'pointer' }}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap="10px">
                            <CardMedia component="img" src={EthIcon} alt="simbol" sx={{ width: '6%' }} />
                                <Box>{t("modal_switch_network.switch_network_text")} {project.blockchain.blockchain_name}</Box>
                        </Box>
                    </Container>
                </Box>
            }
        </Modal>
    )
}

ModalSwitchNetwork.propTypes = {
    project: PropTypes.object,
    open: PropTypes.bool,
    setOpen : PropTypes.func
};



export default ModalSwitchNetwork