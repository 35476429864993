import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import BackGroundDefault from 'assets/background/backgroundBannerDefault.svg'
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import ModalCustom from 'components/ModalCustom';
import FormMyData from './components/FormMyData';
import Web3 from 'web3';
import PropTypes from 'prop-types'

const ActiveTag = styled(Box)`
    font-weight: ${props => props.active == "true" ? 'bold' : 'null'};
    opacity: ${props => props.active == "true" ? '1':'0.5'};
    ${props => props.styles}
`

const Header = ({
    formEditProfile,
    setFormEditProfile,
    address,
    nftOption,
    totalNFTs,
    getMyDataProfile,
    loadMyData,
    user,
    data,
    dataTemp,
    setDataTemp,
    openModalForm,
    setOpenModalForm
}) => {
    const {t} = useTranslation("translate");
    
    return (
        <Box
            sx={{
                width:'100%',
                height:'100%',
                position:'relative',
                backgroundImage:`url(${formEditProfile.banner != '' && formEditProfile.banner != undefined ? formEditProfile.banner:BackGroundDefault})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                objectFit: '100%',
                objectPosition: '100%',
                zIndex:'2',
                mb:'56px'
            }}
        >
            <Box
                sx={{
                    width:'100%',
                    height:'calc(100% + 66px)',
                    minHeight:'calc(170px + 66px)',
                    p:{xs:'16px 16px',sm:'16px 36px'},
                    boxSizing: 'border-box'
                }}
            >   
                <Box 
                    sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        gap:'2rem',
                        "@media screen and (max-width:360px)":{
                            display:'grid',
                            gridTemplateColumns:'repeat(1,1fr)'
                        }
                    }}>
                    <Box
                        sx={{
                            position:'relative',
                            width:{xs:'80px',sm:'120px'},
                            height:{xs:'80px',sm:'120px'},
                            "@media screen and (max-width:360px)":{
                                margin:'0 auto'
                            }
                        }}
                    >
                        <Avatar 
                            variant="circular" 
                            src={formEditProfile.avatar != ''?formEditProfile.avatar:'/static/images/avatar/1.jpg'}
                            sx={{
                                width:{xs:'80px',sm:'120px'},
                                height:{xs:'80px',sm:'120px'}
                            }} 
                        />
                        <Box
                            sx={{
                                position:'absolute',
                                width:'100%',
                                right:'0px',
                                bottom:'0px'
                            }}
                        >   
                            {
                                data && data.userAccount != 'undefined' && String(data.userAccount+'').toUpperCase() == String(address+'').toUpperCase() &&
                                <Box
                                    onClick={()=>{!loadMyData && getMyDataProfile();}}
                                    sx={{
                                        position:'absolute',
                                        bottom:'10px',
                                        right:'0px',
                                        width:'30px',
                                        height:'30px',
                                        borderRadius: '50% 50%',
                                        color:'#fff',
                                        cursor:'pointer',
                                        background:'#43B02A',
                                        "&:hover":{background:'#2A9114'}
                                    }}
                                >
                                    {
                                        loadMyData ?
                                            <CircularProgress size={20} sx={{color:'#fff',ml:'5px',mt:'5px'}}  />
                                        :
                                            <EditIcon sx={{ml:'5px',mt:'5px',fontSize:'20px'}} 
                                        />
                                    }
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center'
                        }}
                    >
                        <Box
                            sx={{display:'grid',gridTemplateColumns:'repeat(1,1fr)',color:'#fff'}}
                        >
                            <Box 
                                sx={{
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: {xs:'23px',sm:'32px',md:'32px',lg:'32px',xl:'32px'},
                                    lineHeight: '100%',
                                    margin:'0px 0px'
                                }}
                            >
                                {
                                    formEditProfile && formEditProfile.username && formEditProfile.username != '' && !Web3.utils.isAddress(formEditProfile.username)  ?
                                    <React.Fragment>
                                        {formEditProfile.username}
                                    </React.Fragment>
                                    :
                                    (address).substring(0,5)+ '...' +(address).substring(38,54)
                                }
                            </Box>
                            <Box
                                sx={{
                                    mt:'6px',
                                    fontStyle: 'normal',
                                    fontWeight: '300',
                                    fontSize: '12px',
                                    lineHeight: '100%',
                                    display:'grid',
                                    letterSpacing: '-0.408px',
                                    gridTemplateColumns:'repeat(2,1fr)',
                                    maxWidth:'240px',
                                    "@media screen and (max-width:490px)":{
                                        gridTemplateColumns:'repeat(1,1fr)',
                                    }
                                }}
                            >
                                <Box
                                >
                                    <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.19434 5.12891L4.9998 0.546875L7.80527 5.12891L4.9998 6.76328L2.19434 5.12891Z" fill="white"/>
                                        <path d="M4.9999 7.2781L7.71553 5.59216L4.9999 9.4531L2.22021 5.59216L4.9999 7.2781Z" fill="white"/>
                                    </svg>
                                    <a href={`${process.env.REACT_APP_SCAN}address/`+String(address+'').toLowerCase()} style={{textDecoration:'none',color:'#fff'}} target="_blank" rel="nofollow noopener noreferrer">
                                        {(address).substring(0,5)+ '...' +(address).substring(38,54)}
                                    </a>
                                </Box>
                                <Box>
                                   
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    mt:'16px',
                                    mb:{xs:'56px',md:'56px'},
                                    fontStyle: 'normal',
                                    fontWeight: '300',
                                    fontSize: '15px',
                                    lineHeight: '100%'
                                }}
                            >
                                {
                                    formEditProfile && formEditProfile.description != '' ?
                                    formEditProfile.description
                                    :
                                    ''
                                }
                            </Box>
                        </Box>
                    </Box>  
                </Box>
            </Box>
            <Box
                sx={{
                    width:'100%',
                    height:'56px',
                    background:'rgba(0, 0, 0, 0.65)',
                    position:'absolute',
                    bottom:'0px',
                    left:'0px',
                    p:'16px 36px'
                }}
            >
                <Box
                    sx={{
                        height:'100%',
                        width:'100%',
                        display:'flex',
                        alignItems:'center'
                    }}
                >   
                    <Box
                        sx={{
                            display:'flex',justifyContent: 'flex-start',gap:'20px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '20px',
                            lineHeight: '100%',
                            color:'#fff'
                        }}
                    >
                        <ActiveTag active={nftOption+''}  component='div' sx={{margin:'auto 25px', cursor:'pointer'}} >
                            {t("profile.tab_all")} ({totalNFTs})
                        </ActiveTag>
                    </Box>
                </Box>
            </Box>
            <ModalCustom 
                isOpen={openModalForm}
                onCloseModal={()=>setOpenModalForm(false)}
                title={t("profile.modal_edit.title")}    
                height={'auto'}    
            >
                <Box
                    sx={{
                        width:'100%',
                        height:{xs:'90vh',sm:'90vh',md:'100%',lg:'100%',xl:'auto'},
                        overflowY:'auto'
                    }}
                > 
                    <FormMyData 
                        formEditProfile={formEditProfile}
                        setFormEditProfile={setFormEditProfile}
                        setInitEditProfile={()=>{}}
                        isSetEditProfile={()=>{}}
                        user={user}
                        data={data}
                        dataTemp={dataTemp}
                        setDataTemp={setDataTemp}
                        setOpenModalForm={setOpenModalForm}
                    />
                </Box>
            </ModalCustom>
        </Box>
    )
}

Header.propTypes = {
    formEditProfile: PropTypes.object,
    setFormEditProfile: PropTypes.func,
    address: PropTypes.string,
    nftOption: PropTypes.bool,
    totalNFTs: PropTypes.number,
    getMyDataProfile: PropTypes.func,
    loadMyData: PropTypes.bool,
    user: PropTypes.object,
    data: PropTypes.object,
    dataTemp: PropTypes.object,
    setDataTemp: PropTypes.func,
    openModalForm: PropTypes.bool,
    setOpenModalForm: PropTypes.func
}


export default Header;
