import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CardNFT from 'components/Cards/CardNFT'
import { isMinted } from 'services/ERC721/isMinted';

const NFTs = ({content,loadingNFTs,limit,setSliceNFT,listCards, finishAddCards}) => {
    const {t} = useTranslation("translate");
    let contentCards = 'sectionNFts';
    let allCards = document.querySelectorAll("#sectionNFts .nftCard");
    let ultimo = null;

   
    React.useEffect(()=>{   
        if(!loadingNFTs && content && limit <= content.length){
            let observerNFtExplore = new IntersectionObserver((cards)=>{
                cards.forEach((card)=>{
                    if(card.isIntersecting){
                        observerNFtExplore.unobserve(ultimo)
                        setSliceNFT(limit + listCards)
                    }
                })
            },
            {
                rootMargin:'0px 0px 270px 0px',
                threshold:1.0
            })

            allCards = document.querySelectorAll("#sectionNFts .nftCard");
            if(allCards && allCards.length > 0){
                ultimo = allCards[allCards.length-1];
                observerNFtExplore.observe(allCards[allCards.length-1])
            }
        }
    }, [allCards,loadingNFTs,limit,content]);

    if(finishAddCards && content == null || finishAddCards && (content && content.length === 0)) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',mt:'20px',color:'#fff'}}
            >
                <h2>
                    {t("profile.any_results")}
                </h2>
            </Box>
     
        )
    }
    
    return (
        <>
            {finishAddCards && !loadingNFTs &&
                <Box
                    sx={{
                        width:'100%',
                        display:'grid',
                        gridTemplateColumns:'repeat(4,1fr)',
                        gap:'20px',
                        ["@media screen and (max-width:1050px)"]:{
                            gridTemplateColumns:'repeat(3,1fr)',
                        },
                        ["@media screen and (max-width:750px)"]:{
                            gridTemplateColumns:'repeat(2,1fr)',
                        }
                    }}
                    id={contentCards}
                >
                    {
                        content?.slice(0, limit).map((item, index)=>{
                            return (isMinted(item) &&
                                <Box
                                    key={index}
                                    sx={{
                                        width:'100%',
                                        minHeight:{xs:'180px',sm:'280px',md:'280px',lg:'280px',xl:'280px'}
                                    }}
                                    className="nftCard"
                                >
                                    <CardNFT item={item}/>
                                </Box>
                            )
                        })
                    }
                </Box>
            }
        </>
    )
}

NFTs.defaultProps = {
    content: [],
    loadingNFTs: false,
    limit: 10,
    setSliceNFT: ()=>{},
    listCards: 100
}

NFTs.propTypes = {
    content: PropTypes.array,
    loadingNFTs: PropTypes.bool,
    limit: PropTypes.number,
    setSliceNFT: PropTypes.func,
    listCards: PropTypes.number,
    finishAddCards: PropTypes.bool
}

export default NFTs;
