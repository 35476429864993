import React, { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import {  Box, AppBar  } from '@mui/material';
import DesktopBar from './components/DesktopBar/DesktopBar';
import { useTranslation } from 'react-i18next';
import MobileBar from './components/MobileBar';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import GridOnIcon from '@mui/icons-material/GridOn';
import FeedIcon from '@mui/icons-material/Feed';
import GroupsIcon from '@mui/icons-material/Groups';
import { topBar } from 'api/sanity';
import LinkIcon from '@mui/icons-material/Link';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomNavLink = styled(NavLink)`
    text-decoration: none;
    color: #fff;
    border: none;
    background: '#fff';
    padding: 0px 0px;
`;

const MenuResponsive = ({items})=>{

    return (
        <AppBar position="static" sx={{m:'0px 0px',p:'0px 0px',backgroundColor:'transparent',boxShadow:'none'}}>
            <Box sx={{height:'100%',m:'0px 0px',p:'0px 0px'}}>
                <Toolbar disableGutters sx={{m:'0px 0px',p:'0px 0px'}}>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    m:'0px 0px',p:'0px 0px'
                    }}
                >
                   <svg width="96" height="24" viewBox="0 0 96 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.2993 4.23752H23.263C22.1324 1.65973 19.5861 0 16.7532 0H7.11607C3.19822 0 0 3.19349 0 7.12867C0 9.01043 0.713339 10.7772 2.00302 12.1079C3.33994 13.4826 5.11148 14.2495 7.01686 14.2841H16.9296C17.7295 14.2841 18.5689 14.1314 19.3515 13.8369V16.8446C19.3515 18.2539 18.202 19.405 16.7957 19.405H1.98727V24H16.7973C17.9642 24 19.0586 23.7323 20.0475 23.2063C20.6081 22.904 21.134 22.5387 21.6317 22.0631C23.1008 20.6979 23.9118 18.8318 23.9118 16.8083V7.15544C23.9118 6.21849 23.7244 5.27367 23.3591 4.35404L23.2961 4.23752H23.2993ZM19.3357 7.16331C19.3357 8.53802 18.2665 9.66078 16.9044 9.72377H7.00112C5.63743 9.66078 4.56978 8.53802 4.56978 7.16331C4.56978 5.7886 5.71931 4.60285 7.12552 4.60285H16.7627C18.1972 4.59497 19.3373 5.74608 19.3373 7.16331H19.3357Z" fill="white"/>
                        <path d="M95.1039 4.36349L95.0504 4.23752H95.0142C93.882 1.65973 91.3357 0 88.5028 0H78.8656C74.9478 0 71.7496 3.19349 71.7496 7.12867C71.7496 9.01043 72.4629 10.7772 73.7526 12.1079C75.0895 13.4826 76.861 14.2495 78.7664 14.2841H88.6792C89.4791 14.2841 90.3184 14.1314 91.1011 13.8369V16.8446C91.1011 18.2539 89.9531 19.405 88.5453 19.405H73.7337V24H88.5453C89.7122 24 90.8066 23.7323 91.7955 23.2063C92.3561 22.904 92.882 22.5387 93.3796 22.0631C94.8488 20.6979 95.6598 18.8318 95.6598 16.8083V7.15544C95.6519 6.21849 95.4645 5.28154 95.0992 4.36192H95.1008L95.1039 4.36349ZM91.0963 7.16488C91.0963 8.5396 90.0271 9.66236 88.665 9.72535H78.7601C77.3964 9.66236 76.3288 8.5396 76.3288 7.16488C76.3288 5.79017 77.4767 4.60442 78.8845 4.60442H88.5217C89.9468 4.59655 91.0963 5.74765 91.0963 7.16488Z" fill="white"/>
                        <path d="M68.9754 9.73799H64.4088V7.80741C64.4088 7.62317 64.2938 6.70984 63.7647 5.92722C63.16 5.03121 62.2404 4.61549 60.8704 4.61549H50.0869V0.0472871H60.8704C64.5347 0.0472871 66.5252 1.85347 67.5487 3.3699C68.6006 4.92886 68.9754 6.73346 68.9754 7.80583V9.73799Z" fill="#FFFF00"/>
                        <path d="M60.8652 23.9827H50.0817V19.416H60.8652C62.2352 19.416 63.1548 18.9987 63.7595 18.1043C64.2886 17.3217 64.4036 16.4084 64.4036 16.2241V14.2935H68.9702V16.2241C68.9702 17.2965 68.597 19.1027 67.5435 20.6601C66.52 22.1765 64.5295 23.9827 60.8652 23.9827Z" fill="white"/>
                        <path d="M31.2187 9.74895H26.6521V7.81837C26.6521 6.74599 27.0253 4.93981 28.0787 3.38244C29.1023 1.866 31.0911 0.0582439 34.757 0.0582439H45.5406V4.62487H34.757C33.3871 4.62487 32.4674 5.04217 31.8627 5.9366C31.3336 6.71922 31.2187 7.63255 31.2187 7.81679V9.74895Z" fill="#F50537"/>
                        <path d="M45.5452 23.9952H34.7617C31.0973 23.9952 29.1069 22.189 28.0834 20.6726C27.0315 19.1136 26.6567 17.309 26.6567 16.2367V14.3061H31.2233V16.2367C31.2233 16.4209 31.3383 17.3342 31.8674 18.1169C32.472 19.0129 33.3917 19.4286 34.7617 19.4286H45.5452V23.9952Z" fill="#00FD90"/>
                    </svg>
                </Typography>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    m:'0px 0px',p:'0px 0px'
                    }}
                >
                    <svg width="96" height="24" viewBox="0 0 96 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.2993 4.23752H23.263C22.1324 1.65973 19.5861 0 16.7532 0H7.11607C3.19822 0 0 3.19349 0 7.12867C0 9.01043 0.713339 10.7772 2.00302 12.1079C3.33994 13.4826 5.11148 14.2495 7.01686 14.2841H16.9296C17.7295 14.2841 18.5689 14.1314 19.3515 13.8369V16.8446C19.3515 18.2539 18.202 19.405 16.7957 19.405H1.98727V24H16.7973C17.9642 24 19.0586 23.7323 20.0475 23.2063C20.6081 22.904 21.134 22.5387 21.6317 22.0631C23.1008 20.6979 23.9118 18.8318 23.9118 16.8083V7.15544C23.9118 6.21849 23.7244 5.27367 23.3591 4.35404L23.2961 4.23752H23.2993ZM19.3357 7.16331C19.3357 8.53802 18.2665 9.66078 16.9044 9.72377H7.00112C5.63743 9.66078 4.56978 8.53802 4.56978 7.16331C4.56978 5.7886 5.71931 4.60285 7.12552 4.60285H16.7627C18.1972 4.59497 19.3373 5.74608 19.3373 7.16331H19.3357Z" fill="white"/>
                        <path d="M95.1039 4.36349L95.0504 4.23752H95.0142C93.882 1.65973 91.3357 0 88.5028 0H78.8656C74.9478 0 71.7496 3.19349 71.7496 7.12867C71.7496 9.01043 72.4629 10.7772 73.7526 12.1079C75.0895 13.4826 76.861 14.2495 78.7664 14.2841H88.6792C89.4791 14.2841 90.3184 14.1314 91.1011 13.8369V16.8446C91.1011 18.2539 89.9531 19.405 88.5453 19.405H73.7337V24H88.5453C89.7122 24 90.8066 23.7323 91.7955 23.2063C92.3561 22.904 92.882 22.5387 93.3796 22.0631C94.8488 20.6979 95.6598 18.8318 95.6598 16.8083V7.15544C95.6519 6.21849 95.4645 5.28154 95.0992 4.36192H95.1008L95.1039 4.36349ZM91.0963 7.16488C91.0963 8.5396 90.0271 9.66236 88.665 9.72535H78.7601C77.3964 9.66236 76.3288 8.5396 76.3288 7.16488C76.3288 5.79017 77.4767 4.60442 78.8845 4.60442H88.5217C89.9468 4.59655 91.0963 5.74765 91.0963 7.16488Z" fill="white"/>
                        <path d="M68.9754 9.73799H64.4088V7.80741C64.4088 7.62317 64.2938 6.70984 63.7647 5.92722C63.16 5.03121 62.2404 4.61549 60.8704 4.61549H50.0869V0.0472871H60.8704C64.5347 0.0472871 66.5252 1.85347 67.5487 3.3699C68.6006 4.92886 68.9754 6.73346 68.9754 7.80583V9.73799Z" fill="#FFFF00"/>
                        <path d="M60.8652 23.9827H50.0817V19.416H60.8652C62.2352 19.416 63.1548 18.9987 63.7595 18.1043C64.2886 17.3217 64.4036 16.4084 64.4036 16.2241V14.2935H68.9702V16.2241C68.9702 17.2965 68.597 19.1027 67.5435 20.6601C66.52 22.1765 64.5295 23.9827 60.8652 23.9827Z" fill="white"/>
                        <path d="M31.2187 9.74895H26.6521V7.81837C26.6521 6.74599 27.0253 4.93981 28.0787 3.38244C29.1023 1.866 31.0911 0.0582439 34.757 0.0582439H45.5406V4.62487H34.757C33.3871 4.62487 32.4674 5.04217 31.8627 5.9366C31.3336 6.71922 31.2187 7.63255 31.2187 7.81679V9.74895Z" fill="#F50537"/>
                        <path d="M45.5452 23.9952H34.7617C31.0973 23.9952 29.1069 22.189 28.0834 20.6726C27.0315 19.1136 26.6567 17.309 26.6567 16.2367V14.3061H31.2233V16.2367C31.2233 16.4209 31.3383 17.3342 31.8674 18.1169C32.472 19.0129 33.3917 19.4286 34.7617 19.4286H45.5452V23.9952Z" fill="#00FD90"/>
                    </svg>
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },ml:'10px' }}>
                    {items.map((item,index) => (
                    <Button
                        key={index}
                        sx={{color: 'white', display: 'block',background:'transparent',"&:hover":{background:'transparent'}}}
                        component={CustomNavLink}
                        to={item.path}
                    >
                        {item.name}
                    </Button>
                    ))}
                </Box>
                </Toolbar>
            </Box>
        </AppBar>
    )
}

MenuResponsive.propTypes = {
    items: PropTypes.array
}


let initItems = [
    {
        name:'Home',
        path:'/home',
        visible:true,
        public:false
    },
    {
        name:'Collections',
        path:'/collections',
        visible:true,
        public:false
    },
    {
        name:'Marketplace',
        path:'/collections',
        visible:true,
        public:false
    },
   /*  {
        name:'Changes',
        path:'/changes',
        visible:true,
        public:false
    } */
]

const TopBar = () => {
    const { t } = useTranslation("translate");
    const [items,setItems] = React.useState(initItems);
    const { setOpenNav } = useContext(DrawerMobileContext);

    const handleOpenNav = () => {
        setOpenNav(true);
    }

    const validationIsPublicLink = (item) =>{
        let valid = false;
        if(item && !item.externalLink && !item.internalLink || item.externalLink && item.internalLink){
            return false;
        }else{
            if(item && item.externalLink){
                valid = true;
            }
        }
        return valid;
    }

    const validationTitleOrICon = (text,getIcon) =>{
        if(String(text).toUpperCase().includes(String(t('topbar.explore'))) || String(text).toUpperCase().includes("EXPLORE")){
            return !getIcon? t('topbar.explore'):GridOnIcon
        }
        if(String(text).toUpperCase().includes(String(t('topbar.curators'))) || String(text).toUpperCase().includes("CURATORS")){
            return !getIcon ? t('topbar.curators'):GroupsIcon
        }
        if(String(text).toUpperCase().includes(String(t('topbar.about'))) || String(text).toUpperCase().includes("ABOUT")){
            return !getIcon ? t('topbar.about'):FeedIcon
        }
        return !getIcon ? text:LinkIcon;
    }


    React.useEffect(() => {
        let temp = 22;
        if(temp == -22){
            topBar().then(response=>{
                try{
                    if(response[0] && response[0].menu){
                        let newArray = new Array();
                        response[0].menu?.map((item)=>{
                            newArray.push({
                                name: validationTitleOrICon(item.title,false),
                                icon: validationTitleOrICon(item.title,true),
                                path: item.link,
                                visible : true,
                                public: validationIsPublicLink(item)
                            })
                        })
                        setItems(newArray)
                    }
                }catch (e) {
                    console.error(e)
                }
            });
        }
    },[]);


    return (
        <React.Fragment>
            <Box
                sx={{
                    width:'100%',
                    height:'65px',
                    background: 'rgba(0, 0, 0, 0.35)'
                }}
            >
                <Container maxWidth="xl" sx={{height:'100%'}}>
                    <Box 
                        sx={{display:'flex',alignItems:'center',height:'100%'}}
                    >
                        <Box
                            sx={{
                                width:'100%',display:'flex',justifyContent:'space-between'
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    gap:'10px'
                                }}
                            >
                                <MenuResponsive items={items} />
                            </Box>
                            <Box
                                sx={{
                                    display:'flex',
                                    justifyContent:'flex-end',
                                    alignItems:'center',
                                    gap:'10px'
                                }}
                            >   
                                <DesktopBar tabs={items} />
                                <MobileBar tabs={items} />
                                <MenuIcon onClick={handleOpenNav} sx={{display: { sm: 'block', md: 'none' },color:'#D9D9D9',cursor:'pointer'}}/>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
}

export default TopBar;
