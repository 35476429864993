import React, {  lazy,useState,useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { useFetch } from 'hooks/useFetch';
import { useLocation } from 'react-router-dom';
import ErrorMessage from 'components/ErrorMessage';
import LoaderCircle from 'components/LoaderCircle';
import { useTranslation } from 'react-i18next'; 
import { Typography } from '@mui/material';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { isMinted } from 'services/ERC721/isMinted';
import NFTs from 'views/Collections/components/NFTs';
import CardNFT from './CardNFT';
import { Link } from 'react-router-dom';
const NavInfo = lazy(() => import('./components/NavInfo'));


const NFT = () => {
    const { t } = useTranslation("translate"); 
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const address = query.get("address");
    const tokenId = query.get("token_id");

    const [nft,setNFT] = useState(null);
    const [collapseDescription, setCollapseDescription]=useState(false);

    const [url,setUrl]= useState(`${process.env.REACT_APP_URL_API}/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}`);
    const [countError1,setCountError1] = useState(0);
    let {data, error, loading} = useFetch(url,countError1);
    
    const limitNFTRef = React.useRef(6);
    const pageNftRef = React.useRef(0);
    const urlNft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created`
    let { data: nftData, error:errorNFt, loading:loadingNFT} = useFetch(urlNft);

    let timeOut1 = null ;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        timeOut1 = null;
        clearTimeout(timeOut1)
        setUrl(`${process.env.REACT_APP_URL_API}/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}`)
        if(!loading && error) {
            if(countError1 < 3){
                timeOut1 = setTimeout(() =>{
                    setCountError1(countError1+1)
                    clearTimeout(timeOut1)
                    return null;
                },5000);
               
            }else{
                return null;
            }
        }
    },[error,countError1,loading]);

    useEffect(() => {
        if(!loading && data && data.length > 0){
            setNFT(data[0])
        }
    },[data,loading]);


    useEffect(() => {
        setCountError1(0)
        setUrl(`${process.env.REACT_APP_URL_API}/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}`)
    },[address,tokenId]);

    if(loading){
        return (
            <LoaderCircle text={t('message_loader.nft_screen.loading')} />
        )
    }

    return (
        <Container maxWidth="xl" sx={{ color: '#fff', mt: 5 }}>

            {
                error && (countError1 == 3) && !loading && <ErrorMessage error={error} /> 
            }
            {(loading || countError1 < 3) && !data &&
            <Box sx={{width:'100%',height:'100%',minHeight: '100%',boxSizing:'border-box'}}>
                <LoaderCircle text={t('message_loader.nft_screen.loading')} />
            </Box>}
            {
                !loading && data && data.length > 0 &&
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                    sx={{ mt: 0 }}
                >
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        { nft && nft.reveal.confirmed &&  isMinted(nft)  ?
                            <CardNFT item={nft} />
                            :
                            <Box
                                sx={{
                                    boxShadow:'inset 0px 0px 20px rgba(0,0,0,.5)',
                                    borderRadius:'8px 8px',
                                    minHeight:'300px',
                                    display:'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box component={"h3"}
                                    sx={{
                                        textAlign: 'center',
                                        color:'#fff',
                                        fontSize:'120px',
                                        margin:'10px 10px',
                                        WebkitTextStroke:'1px black'
                                    }}
                                > 
                                    ?
                                </Box>
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Box sx={{ fontSize: '3em' }}>
                            {  nft && nft.reveal.confirmed &&  isMinted(nft)  ? 
                            <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}}>
                                {nft && nft.metadata && nft.metadata.json_data.name ?
                                    <p style={{marginTop:'10px'}}>{nft && nft.metadata && nft.metadata.json_data.name}</p>
                                    :  nft && nft.metadata && nft.metadata.json_data.attributes.map((attribute,index) => (
                                        attribute.trait_type == 'Name' && <p key={index} style={{marginTop:'10px'}}>{attribute.value}</p>
                                    ))
                                }
                            </Typography> :
                            <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}} >{t("nft-screen.name_not_available")}</Typography>
                            }
                        </Box>
                        <Box
                            sx={{width:'100%', display:'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'}}
                        >
                            <svg width="20" height="20" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.542969" width="12" height="12" rx="6" fill="#4E4E4E"/>
                                <path d="M6.58538 9.08C6.07338 9.08 5.62004 9.00533 5.22538 8.856C4.83071 8.70667 4.51604 8.472 4.28138 8.152C4.05204 7.832 3.92671 7.41867 3.90538 6.912C3.90004 6.69333 3.89738 6.45867 3.89738 6.208C3.89738 5.95733 3.90004 5.71733 3.90538 5.488C3.92671 4.992 4.05471 4.584 4.28938 4.264C4.52938 3.944 4.84671 3.70667 5.24138 3.552C5.64138 3.39733 6.08938 3.32 6.58538 3.32C6.92671 3.32 7.25471 3.36 7.56938 3.44C7.88938 3.51467 8.17738 3.63467 8.43338 3.8C8.68938 3.96533 8.89204 4.17867 9.04138 4.44C9.19604 4.696 9.27604 5.00533 9.28138 5.368C9.28138 5.416 9.26271 5.45867 9.22538 5.496C9.19338 5.528 9.15338 5.544 9.10538 5.544H7.47338C7.38804 5.544 7.32404 5.528 7.28138 5.496C7.24404 5.45867 7.20938 5.39733 7.17738 5.312C7.11871 5.14133 7.03871 5.02667 6.93738 4.968C6.83604 4.90933 6.71604 4.88 6.57738 4.88C6.40138 4.88 6.26004 4.928 6.15338 5.024C6.05204 5.11467 5.99604 5.28267 5.98538 5.528C5.96938 5.96533 5.96938 6.41333 5.98538 6.872C5.99604 7.11733 6.05204 7.288 6.15338 7.384C6.26004 7.47467 6.40138 7.52 6.57738 7.52C6.71604 7.52 6.83604 7.488 6.93738 7.424C7.04404 7.36 7.12404 7.248 7.17738 7.088C7.20404 6.99733 7.23871 6.936 7.28138 6.904C7.32404 6.872 7.38804 6.856 7.47338 6.856H9.10538C9.15338 6.856 9.19338 6.87467 9.22538 6.912C9.26271 6.944 9.28138 6.984 9.28138 7.032C9.27604 7.39467 9.19604 7.70667 9.04138 7.968C8.89204 8.224 8.68938 8.43467 8.43338 8.6C8.17738 8.76533 7.88938 8.888 7.56938 8.968C7.25471 9.04267 6.92671 9.08 6.58538 9.08Z" fill="white"/>
                            </svg>
                            <small style={{margin:'0px 0px',fontSize:'19px',color:'#FFFFFF',fontWeight: 400,opacity:0.5}}>
                                {
                                    nft && nft.project && nft.project.name
                                }
                            </small>
                        </Box>
                        <Box>
                            {  nft && nft.reveal.confirmed &&  isMinted(nft)   && nft.metadata && nft.metadata.json_data.description ?
                                <Typography gutterBottom variant="h6" component="h2" sx={{color:'#fff'}} >
                                    {collapseDescription ? 
                                        <p style={{marginTop:'10px'}}>{nft.metadata.json_data.description}</p> : 
                                        <p style={{marginTop:'10px'}}>{nft.metadata.json_data.description.substring(0,500)}</p>
                                    }
                                    { nft.metadata.json_data.description.length > 500 ?
                                    <React.Fragment>
                                        {
                                        !collapseDescription ? 
                                        <TiArrowSortedDown onClick={()=>setCollapseDescription(!collapseDescription)} style={{cursor:'pointer'}} size={20} /> 
                                        :
                                        <TiArrowSortedUp onClick={()=>setCollapseDescription(!collapseDescription)} style={{cursor:'pointer'}} size={20} />
                                        }
                                    </React.Fragment> 
                                    : null }
                                </Typography>
                                :nft && nft.metadata && nft.metadata.json_data.attributes.map((attribute,index) => (
                                    attribute.trait_type == 'Description' && <p key={index} style={{marginTop:'10px'}}>  {attribute.value}</p>
                                ))
                            }
                        </Box>
                        {
                            nft && nft.reveal.confirmed && isMinted(nft) &&
                            <NavInfo nft={nft}  />
                        }
                        
                    </Grid>
                </Grid>
            }
            {
                !errorNFt && nftData && !loadingNFT && !error && !loading && 
                <React.Fragment>
                    <Divider sx={{ width: '100%', color: '#fff', mt: 5 }} />
                    <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
                        <Box>{t("nft-screen.more_from_this_collection")}</Box>
                        <Box><Link to={"/marketplace"} style={{textDecoration:'none',color:'#fff'}}>{t("nft-screen.view_all")}</Link></Box>
                    </Box>
                </React.Fragment>
            }
            <Box sx={{width:'100%',mt:'20px'}}>
            {
                !errorNFt && nftData && !loadingNFT && nftData.length > 0 && nftData.filter((nft) => nft.transaction == "PRE_MINT").length == 0 &&
                <NFTs content={nftData} />
            }
            {
                !errorNFt && nftData && !loadingNFT && nftData.length > 0 &&
                nftData.filter((nft) => nft.transaction == "PRE_MINT").length > 1 && 
                <Container maxWidth="md" sx= {{ mt: 5 }}>
                    <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}} >
                        {t("nft-screen.nft_not_available")}
                    </Typography>
                </Container>
            }
                {
                    !errorNFt && loadingNFT && !loading && !error && !loading && 
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <LoaderCircle text={t("collection_buy_view.loading")} />
                    </Box>
                }
                {
                    !loadingNFT && errorNFt && !loading && !error && !loading && 
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <ErrorMessage error={errorNFt} />
                    </Box>
                }
            </Box>
        </Container>
    );
};

export default NFT;
